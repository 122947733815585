import React, { useEffect } from 'react'
import { Expander } from '../Expander'
import { FiCreditCard } from 'react-icons/fi'
import { Label, Subtitle, Text } from '../../design'
import { AddressForm, AddressValues } from '../AddressForm'
import { DateField, NumericField, PhoneField, Selector, TextField } from '../Fields'
import { CpfField } from '../Fields/CpfField'
import { mockInstallments } from '../../../helpers/mocks/installments'
import { useCartContext } from '../../../contexts'

interface CreditCardValues extends AddressValues {
    cardNumber?: string;
    cardCvv?: string;
    cardValidDate?: string;
    cardHolder?: string;
    cardInstallments?: number;
    cpf?: string;
    birthday?: string;
    phone?: string;
}

interface Props {
    selected: boolean;
    values: CreditCardValues;
    onToggleSelect: () => void;
    onChange: (field: string, value: unknown) => void;
    onBlur: (field: string, value: string) => void;
}

export const CreditCard = ({ selected, onToggleSelect, onChange, onBlur, values }: Props): JSX.Element => {
    const { currentCart } = useCartContext()

    function calculateValueInstallments(e?: React.ChangeEvent<HTMLSelectElement>) {
        const price = currentCart.event?.totalPrice

        if (!price)
            return

        mockInstallments.map((i) => {
            if (e)
                (i.value === parseFloat(e.target.value)) ? i.selected = true : i.selected = false;

            if (i.value === 0) {
                i.label = `${i.times}x R$ ${price} (sem juros)`
            } else {
                const jurus    = String(i.value * parseFloat(String(price)) / 100);
                const newPrice = parseFloat(String(price))
                const newJurus = parseFloat(jurus)
                const priceWithinterest     = newPrice + newJurus;
                const newPriceWithInterest  = priceWithinterest.toFixed(2)
                const priceInInstallment    = priceWithinterest / i.times
                const newPriceInInstallment = priceInInstallment.toFixed(2);
                i.label = `${i.times}x R$ ${newPriceInInstallment} = R$ ${newPriceWithInterest}`
            }

            return i;
        });
    }

    const handleInstallmentSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        calculateValueInstallments(e)
        onChange('cardInstallments', e.target.value)
    }

    useEffect(() => {
        calculateValueInstallments()
    })

    return (
        <Expander
            title={
                <>
                    <Subtitle className="pr-5"><FiCreditCard /></Subtitle>
                    <Subtitle>Cartão de crédito</Subtitle>
                </>
            }
            open={selected}
            onToggleOpen={onToggleSelect}
        >
            <>
                <div className="mb-4">
                    <Text small className="pb-2">Seu pagamento será realizado via cartão de crédito</Text>
                    <Text small secondary>Informe os dados para a cobrança e o número de parcelas</Text>
                </div>
                <div className="my-2">
                    <Selector label="Número de parcelas" options={mockInstallments} value={values.cardInstallments} onChange={handleInstallmentSelect} />
                </div>
                <div className="mt-4 mb-2">
                    <Label bold>Dados do cartão</Label>
                </div>
                <div className="my-2">
                    <NumericField label="Número do cartão" format="################" required value={values.cardNumber} onChange={(e) => onChange('cardNumber', e.target.value)} />
                </div>
                <div className="my-2 flex flex-row">
                    <div className="mr-3">
                        <DateField label="Validade" format="MM/YYYY" required value={values.cardValidDate} onChange={(e) => onChange('cardValidDate', e.target.value)} />
                    </div>
                    <div>
                        <NumericField label="CVV" format="###" required value={values.cardCvv} onChange={(e) => onChange('cardCvv', e.target.value)} />
                    </div>
                </div>
                <div className="mt-4 mb-2">
                    <Label bold>Dados do titular</Label>
                </div>
                <div className="my-2">
                    <TextField label="Nome do titular" required value={values.cardHolder} onChange={(e) => onChange('cardHolder', e.target.value)} onBlur={(e) => onBlur('cardHolder', e.target.value)} />
                </div>
                <div className="my-2 flex flex-row">
                    <div className="mr-3">
                        <CpfField label="CPF" required value={values.cpf} onChange={(e) => onChange('cpf', e.target.value)} />
                    </div>
                    <div>
                        <DateField label="Data de nascimento" required format="DD/MM/YYYY" value={values.birthday} onChange={(e) => onChange('birthday', e.target.value)} />
                    </div>
                </div>
                <div className="my-2">
                    <PhoneField label="Celular" required value={values.phone} onChange={(e) => onChange('phone', e.target.value)} />
                </div>
                <AddressForm title="Endereço de cobrança do cartão" values={values} onChange={onChange} onBlur={onBlur} />
            </>
        </Expander>
    )
}
