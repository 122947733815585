import React from 'react'
import ContentLoader from 'react-content-loader'

export const HeaderLoader = (): JSX.Element => {
  return (
    <ContentLoader 
      speed={0.8} 
      width={342}
      height={240}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0"   y="10"  rx="4" ry="4" width="100" height="13" />
      <rect x="298" y="10"  rx="4" ry="4" width="40"  height="13" />
      <rect x="0"   y="34"  rx="5" ry="5" width="70"  height="70" />
      <rect x="80"  y="40"  rx="4" ry="4" width="162" height="13" />
      <rect x="0"   y="116" rx="4" ry="4" width="80"  height="13" />
      <rect x="258" y="116" rx="4" ry="4" width="80"  height="13" />
      <rect x="0"   y="160" rx="4" ry="4" width="340" height="13" />
      <rect x="0"   y="206" rx="4" ry="4" width="80"  height="13" />
      <rect x="312" y="190" rx="4" ry="4" width="30"  height="13" />
      <rect x="292" y="206" rx="4" ry="4" width="50"  height="13" />
    </ContentLoader>
  )
}