import React, { ChangeEventHandler, FocusEventHandler, InputHTMLAttributes, useMemo, useState } from 'react'
import { isNotEmpty, isValidPhone } from '../../../validation'
import { Label, Text } from '../../design'
import { Input } from '../../design/Input'
import NumberFormat from 'react-number-format';

interface Props extends InputHTMLAttributes<HTMLInputElement>{
    label?: string;
    placeholder?: string;
    required?: boolean;
    error?: string;
    value?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement|HTMLTextAreaElement>;
}

export const PhoneField = ({label, placeholder, error, required, inputProps, value, onChange, onBlur, name}: Props) : JSX.Element => {
    const [didInput, setDidInput] = useState(false)
    const errorMessage = useMemo(() => {
        if(error) return error

        if(!didInput) return

        if(required && !(value && isNotEmpty(value)))
            return "Campo obrigatório"

        if(value && !isValidPhone(value))
            return "Número inválido"

    }, [required, value, error, didInput])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDidInput(true)

        if(onChange)
            onChange(e)

        isNotEmpty(e.target.value) && isValidPhone(e.target.value) ? setDidInput(false) : setDidInput(true);
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDidInput(true)

        if(onBlur)
            onBlur(e)

        isNotEmpty(e.target.value) && isValidPhone(e.target.value) ? setDidInput(false) : setDidInput(true);
    }

    const format = (tel: string): string => {
        tel = tel.replace(/\D/g,"")
        tel = tel.replace(/(.{2})(\d)/,"($1) $2")
        if(tel.length == 10) {
            tel=tel.replace(/(.{1})$/,"-$1")
        } else if (tel.length == 11) {
            tel=tel.replace(/(.{2})$/,"-$1")
        } else if (tel.length == 12) {
            tel=tel.replace(/(.{3})$/,"-$1")
        } else if (tel.length >= 13) {
            tel=tel.replace(/(.{4})$/,"-$1")
        }
        return tel;
    }

    return (
        <>
            {label && <Label danger={!!errorMessage}>{label}</Label>}
            <NumberFormat 
                name={name}
                maxLength={15}
                customInput={Input} 
                { ...{...inputProps, defaultValue: undefined}} 
                type="tel" 
                error={!!errorMessage} 
                placeholder={placeholder} 
                format={format} 
                value={value} 
                onChange={handleChange}
                onBlur={handleBlur}
            />
            {!!errorMessage && <Text danger>{errorMessage}</Text>}
        </>
    )
}
