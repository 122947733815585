import React, { ChangeEventHandler, FocusEventHandler, InputHTMLAttributes } from 'react'
import { Label, Text } from '../../design'
import { Select } from '../../design/Select'

type SelectorOption = {
    label: string;
    value?: string | number;
    times?: string | number;
    selected?: boolean;
}

interface Props {
    label?: string;
    name?: string;
    placeholder?: string;
    required?: boolean;
    error?: string;
    options: Array<SelectorOption>;
    selectProps?:  React.SelectHTMLAttributes<HTMLSelectElement>;
    value?: string | number;
    onChange?: ChangeEventHandler<HTMLSelectElement>;
    onBlur?: FocusEventHandler<HTMLSelectElement>;
}

export const Selector = ({label, placeholder, options, error, selectProps, value, onChange, onBlur, name}: Props) : JSX.Element => {

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if(onChange)
            onChange(e)
    }

    const handleBlur = (e: React.FocusEvent<HTMLSelectElement>) => {
        if (onBlur)
            onBlur(e)
    }

    return (
        <>
        <div className="my-4">
            {label && <Label data-testid="selector-label" danger={!!error}>{label}</Label>}
            <Select {...selectProps} name={name} onChange={handleChange} onBlur={handleBlur} placeholder={placeholder} value={value}>
                {options.map((option, index) => <option data-testid="selector-option" key={`option-${index}`} value={option.value}>{option.label}</option>)}
            </Select>
            {!!error && <Text danger>{error}</Text>}
        </div>
        </>
    )
}
