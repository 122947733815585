import axios, { AxiosPromise, Method } from "axios";

class API {
    BASE_URL = "https://api.adrenalyze.com.br"
    // BASE_URL = "http://dev.back.adrenalyze.com.br"

    call(url: string, method: Method, payload?: unknown, auth_key?: number | null): AxiosPromise {
        return axios(url, {
            baseURL: this.BASE_URL,
            method,
            params: method !== "GET" ? undefined : payload,
            data: method !== "GET" ? payload : undefined,
            headers: {
                'Authorization': auth_key,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With'
            }
        })
    }
}

export const Api = new API()