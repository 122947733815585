// import { _env } from './Env';

const JUNO_SANDBOX_TOKEN = 'D87FD78FCC04C4DA425B2E2E4C88346ABDF32E5ECA7AB7EA350E2F72672B608B'
const JUNO_PROD_TOKEN = 'FDE249C164DE9729BAF77B9A31354FCFD48964B7DFADF4F4E70CECC8B7CB9C338E2FF1D9B8C43FB5'

export const getJunoToken = () => {
    // const env = _env();
    // if (env !== "prod") {
        return JUNO_PROD_TOKEN
    // } else {
    //     return JUNO_PROD_TOKEN
    // }
}