import { User, UserWithPassword, UserWithToken } from "../domain/types"
import { Api } from "../helpers/api"

export const getAccountByToken = async (token: string) : Promise<User|null> => {
    const res = await Api.call('/v3/user/social-login', 'POST', { token })

    if (res.status !== 200)
        return null

    return res.data as User
}

export const registerAccountByToken = async (data: UserWithToken) : Promise<User> => {
    const res = await Api.call('/v3/user/social-register', 'POST', data)

    if (res.status !== 200)
        throw new Error('Unable to login with this account')

    return res.data as User
}

export const registerAccountByEmail = async (data: UserWithPassword) : Promise<User> => {
    const res = await Api.call('/v3/user/email-register', 'POST', data)

    if (res.status !== 200)
        throw new Error('Unable to register the account')

    return res.data.data as User
}

export const checkEmailForAuth = async (email: string) : Promise<any> => {
    const res = await Api.call('/v3/user/check-user-type', 'POST', { email })

    if (res.status !== 200)
        throw new Error('Unable to check this e-mail')

    return res.data.data
}

export const registerAccountByEmailWithoutPassword = async (data: User) : Promise<User> => {
    const res = await Api.call('/v3/user/register-without-password', 'POST', data )

    if (res.status !== 200)
        throw new Error('Unable to register the account')

    return res.data.data as User
}

export const authAccountByEmail = async (data: UserWithPassword) : Promise<User> => {
    const res = await Api.call('/v3/user/email-login', 'POST', data)

    if (res.status !== 200)
        throw new Error('Unable to register the account')

    return res.data.data as User
}