import { isValidEmail, isValidPhone } from ".";
import { ReserveForm } from "../domain/types";


export const isReserveCompleted = (reserve: ReserveForm): boolean => {
    if(!reserve.name || !reserve.phone || !reserve.email)
        return false

    if(reserve.name.length < 3)
        return false
    
    if(!isValidEmail(reserve.email))
        return false

    if(!isValidPhone(reserve.phone))
        return false

    if(!reserve.accept_privacy_terms)
        return false

    if(!reserve.accept_responsability_terms)
        return false
    
    return true
}