import React from 'react';
import { FiLogIn } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { Button, Title } from '../../../design';
import { useAuthContext } from '../../../../contexts';
import { LoginSteps } from '../../../../helpers/constants';
import { registerAccountByEmailWithoutPassword } from "../../../../services/authService"

export const FormChooseRegister: React.FC = () => {
    const { authData, changeStep, setUserAuthKey } = useAuthContext()
    const push = useNavigate()

    const handleContinueWithoutRegister = async () => {
        if(!authData.email)
            return

        try {
            const user = await registerAccountByEmailWithoutPassword({ email: authData.email, without_password: 1 })
            
            if(!user.auth_key)
                return

            setUserAuthKey(user.auth_key)
            push("/", { replace: true })
        } catch (error) {
            console.error(error)
        }
    }

  return (
      <>
        <div className="my-4">
            <FiLogIn size={50} />
        </div>
        <div className="my-4">
            <Title primary>Você ainda não possui cadastro</Title>
        </div>
        <div className="mt-4 mb-2 w-full text-center">
            <Button onClick={() => changeStep(LoginSteps.REGISTER_NEW_ACCOUNT)} large success>Fazer cadastro agora</Button>
        </div>
        <div className="mt-2 mb-4 w-full text-center">
            <Button onClick={() => handleContinueWithoutRegister()} large secondary>Continuar sem cadastro</Button>
        </div>
      </>
  )
};
