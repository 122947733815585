import React, {Component} from 'react';

import "./PageLoader.css";
import { Text } from '../../design'
import styled from 'styled-components';

interface PageLoaderProps {
    loading: boolean;
}

const PageLoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Loader = styled.div`
    display: flex;
    align-items: center;
    background-color: transparent;
    height: 10.66px;
    width: 36%; 
    padding-top: 24px;
`

export const PageLoader = ({ loading = true }: PageLoaderProps) : JSX.Element => {
    return (
        <>
            {loading &&
                <>
                <PageLoaderContainer>
                    <Container>
                        <Text primary>Aguarde</Text>
                        <Text primary>Isso pode levar alguns segundos</Text>
                        <Loader>
                            <div className="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </Loader>
                    </Container>
                </PageLoaderContainer>
                </>
            }
        </>
    );
}
 