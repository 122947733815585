import React from 'react'
import styled from 'styled-components'

const Input = styled.input`
    position: relative;
    border: 1px solid black;
    height: 12px;
    width: 12px;
    border-radius: 2px;
    margin-right: 10px;

    &:checked{
        &:after{
            content:'';
            position: absolute;
            border-radius: 2px;
            top: 2px;
            right: 2px;
            bottom: 2px;
            left: 2px;
            background: ${props => props.theme.main}
        }
        &:before{
            content:'';
            position: absolute;
            border-radius: 2px;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px solid black;
            background: white;
        }
    }

`

export const Checkbox = (props: React.InputHTMLAttributes<HTMLInputElement>) : JSX.Element => {
    return <Input {...props} type="checkbox" />
}
