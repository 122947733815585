import React from 'react'
import styled from 'styled-components'
import { Divider } from './Divider'


interface Props {
    children: JSX.Element | Array<JSX.Element>
}

const Container = styled.div`
    position: relative;
    padding: 0 4% 4% 4%;
`


export const ModalHeader = ({ children }: Props) : JSX.Element => {
    return (
        <>
            <Container>
                {children}
                <Divider bottom />
            </Container>
        </>
    )
}
