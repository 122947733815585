import React, { ChangeEventHandler, FocusEventHandler, useMemo, useState, InputHTMLAttributes } from 'react'
import { isNotEmpty } from '../../../validation'
import { Label, Text } from '../../design'
import { Input } from '../../design/Input'
import { TextArea } from '../../design/TextArea'


interface Props extends InputHTMLAttributes<HTMLInputElement>{
    label: string;
    value?: string;
    error?: string;
    required?: boolean;
    placeholder?: string;
    multipleLines?: boolean;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
    textAreaProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
    onBlur?: FocusEventHandler<HTMLInputElement|HTMLTextAreaElement>;
    onChange?: ChangeEventHandler<HTMLInputElement|HTMLTextAreaElement>;
}

export const TextField = ({label, placeholder, multipleLines, required, error, inputProps, textAreaProps, value, onChange, onBlur, name}: Props) : JSX.Element => {
    const [didInput, setDidInput] = useState(false)
    const errorMessage = useMemo(() => {
        if(error) return error

        if(!didInput) return

        if(required && !(value && isNotEmpty(value)))
            return "Campo obrigatório"

    }, [required, value, error, didInput])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDidInput(true)

        if(onChange)
            onChange(e)

        isNotEmpty(e.target.value) ? setDidInput(false) : setDidInput(true);
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {        
        setDidInput(true)

        if (onBlur)
            onBlur(e)

            
        isNotEmpty(e.target.value) ? setDidInput(false) : setDidInput(true);
    }

    return (
        <>
        <div className="my-4">
            {label && <Label danger={!!errorMessage}>{label}</Label>}
            {multipleLines ? 
                <TextArea {...textAreaProps} name={name} value={value} error={!!errorMessage} onChange={handleChange} onBlur={handleBlur} placeholder={placeholder} /> 
            : 
                <Input {...inputProps} name={name} value={value} error={!!errorMessage} onChange={handleChange} onBlur={handleBlur} placeholder={placeholder} />
            }
            {!!errorMessage && <Text danger>{errorMessage}</Text>}
        </div>
        </>
    )
}
