import styled, { DefaultTheme } from 'styled-components'

interface TextProps {
    primary?: boolean,
    secondary?: boolean,
    danger?: boolean,
    success?: boolean
    warning?: boolean,
    verySmall?: boolean,
    small?: boolean,
    normal?: boolean,
    clickable?: boolean,
    bold?: boolean,
    theme: DefaultTheme,
    disabled?: boolean,
    textAlign?: 'left' | 'right' |' center' | 'justify' | 'initial'| 'inherit' | 'unset'
}

const reduceColorByProps = ({primary, secondary, success, warning, danger, theme, disabled } : TextProps) : string => {
    if(primary){
        return theme.main
    } else if (secondary) {
        return theme.secondary
    } else if (success) {
        return theme.success
    } else if (danger) {
        return theme.danger
    } else if (warning) {
        return theme.warning
    } else if (disabled) {
        return theme.disabled
    }
    return theme.black
}

const reduceFontSizeByProps = ({verySmall, small, normal} : TextProps) : string => {
    if(verySmall){
        return '12px'
    } else if (small) {
        return '14px'
    } else if (normal) {
        return '16px'
    }
    return '16px'
}

export const Text = styled.p<TextProps>`
    margin: 0;
    font-size: ${reduceFontSizeByProps};
    color: ${reduceColorByProps};
    font-weight: ${props => props.bold ? 'bold' : 'normal'};
    cursor: ${props => props.clickable ? 'pointer' : 'default'};
    user-select: ${props => props.clickable ? 'none' : undefined};
    text-align: ${props => props.textAlign ?? 'unset'}
`