import React, { useState } from 'react'
import { PAYMENT_METHOD_ICON, PAYMENT_METHOD_LABEL } from '../../../helpers/constants'
import { Text } from '../../design'
import { TextField } from '../Fields'
import { Price } from '../Price'

interface Props {
    price: number,
    pix_code: string,
    qrcode_img: string,
}

export const Pix = ({ price, pix_code, qrcode_img }: Props) : JSX.Element => {
  const [textCopied, setTextCopied] = useState(false)

  const handleCopyBarCode : React.FocusEventHandler<HTMLInputElement> = (e)  => {
    if (!e.target.value)
        return
  
    e.target?.select()
    navigator.clipboard.writeText(e.target.value).then(() => setTextCopied(true));
  }

  return (
      <div className="relative">
          <Text primary className="flex-1 flex flex-row" bold>{PAYMENT_METHOD_ICON['PIX']} <span className="ml-3" data-testid="header-payment-method">{PAYMENT_METHOD_LABEL['PIX']}</span></Text>
          <div className="mb-4 flex flex-row justify-between">
            <div className="my-4 flex flex-col justify-start">
              <Text verySmall textAlign='justify'>Abra seu aplicativo do banco para pagamentos PIX e ponte o celular para o QR Code ou copie e cole o código abaixo.</Text>
              <Text verySmall textAlign='justify'>Após pagar, retorne para esta página e aguarde alguns instantes que confirmaremos seu pedido.</Text>
            </div>
            {qrcode_img &&
              <img style={{ display: 'block', margin: 'auto', width: 150 }} src={`data:image/png;base64, ${qrcode_img}`} alt="QRcode Pix" />
            }
          </div>
          <TextField label="" value={pix_code} inputProps={{ onFocus: handleCopyBarCode }} />
          {textCopied && <Text small success>Código copiado!</Text>}
          <div className="my-4 flex flex-row justify-between">
              <Text>Valor do Pix</Text>
              <Price original={price} withDiscount={0.00} />
          </div>
      </div>
  )
}
