import React from 'react'
import { Expander } from '../Expander'
import { FiFileText } from 'react-icons/fi'
import { Label, Subtitle, Text } from '../../design'
import { AddressForm, AddressValues } from '../AddressForm'
import { DateField, PhoneField, TextField } from '../Fields'
import { CpfField } from '../Fields/CpfField'

interface BoletoValues extends AddressValues {
    name?: string;
    cpf?: string;
    birthday?: string;
    phone?: string;
}

interface Props {
    selected: boolean;
    values: BoletoValues;
    onToggleSelect: () => void;
    onChange: (field: string, value: string) => void;
    onBlur: (field: string, value: string) => void;
}

export const Boleto = ({ selected, onToggleSelect, onChange, onBlur, values }: Props): JSX.Element => {
    return (
        <Expander
            title={
                <>
                    <Subtitle className="pr-5"><FiFileText /></Subtitle>
                    <Subtitle>Boleto</Subtitle>
                </>
            }
            open={selected}
            onToggleOpen={onToggleSelect}
        >
            <>
                <div className="mb-4">
                    <Text small className="pb-2">Seu pagamento será realizado via boleto</Text>
                    <Text small secondary>Informe os dados para a emissão do boleto.</Text>
                </div>
                <div className="mt-4 mb-2">
                    <Label bold>Dados do pagante</Label>
                </div>
                <div className="my-2">
                    <TextField label="Nome do titular" required value={values.name} onChange={(e) => onChange('name', e.target.value)} onBlur={(e) => onBlur('cpf', e.target.value)} />
                </div>
                <div className="my-2 flex flex-row">
                    <div className="mr-3">
                        <CpfField label="CPF" required value={values.cpf} onChange={(e) => onChange('cpf', e.target.value)} onBlur={(e) => onBlur('cpf', e.target.value)} />
                    </div>
                    <div>
                        <DateField label="Data de nascimento" required format="DD/MM/YYYY" value={values.birthday} onChange={(e) => onChange('birthday', e.target.value)} onBlur={(e) => onBlur('cpf', e.target.value)} />
                    </div>
                </div>
                <div className="my-2">
                    <PhoneField label="Celular" required value={values.phone} onChange={(e) => onChange('phone', e.target.value)}  />
                </div>
                <AddressForm title="Endereço de cobrança do boleto" values={values} onChange={onChange} onBlur={onBlur} />
            </>
        </Expander>
    )
}
