import styled, { DefaultTheme } from 'styled-components'

interface TextAreaProps {
    error?: boolean;
    theme: DefaultTheme;
}

export const TextArea = styled.textarea<TextAreaProps>`
        padding: 0.5rem 1rem;
        border: 1px solid ${props => props.error ? props.theme.danger : '#DDD'};
        font-weight: 300;
        display: flex;
        width: 100%;
        border-radius: 0.3rem;
        box-shadow: rgba(128,128,128,0.3) 0 1px 3px 0;
    `
