import React from 'react'
import styled from 'styled-components'

interface ModalBodyProps {
    children: JSX.Element | Array<JSX.Element>
}


const Container = styled.div`
    position: relative;
    padding: 4% 2%;
`
export const ModalBody = ({ children }: ModalBodyProps) : JSX.Element => {
    return (
        <Container>
            {children}
        </Container>
    )
}
