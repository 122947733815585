import React, { createContext, useContext, useEffect, useState } from "react"
import { Reserve, ReserveForm } from "../domain/types";
import { useCartContext } from './CartContext'
import { useReserveDraftContext } from "./ReserveDraftContext";

interface ReserveListContextValue {
    draftReserveList: Array<Reserve>;
    currentReserveIndex?: number;
    allReserves: Array<Reserve>;
    selectReserve: (index?: number) => void;
    updateList: (newList: Array<Reserve>) => void;
    setDraftReserveList:(newList: Array<Reserve>) => void;
}

const ReserveListContextProvider = createContext<ReserveListContextValue>({
    draftReserveList: [],
    allReserves: [],
    selectReserve: () => undefined,
    updateList: () => undefined,
    setDraftReserveList: () => undefined
})

export const useReserveListContext = () : ReserveListContextValue => useContext(ReserveListContextProvider); 

interface ContextProps {
    children: JSX.Element | Array<JSX.Element>
}

export const ReserveListContext = ({ children }: ContextProps ) : JSX.Element => {
    const [ draftReserveList, setDraftReserveList ] = useState<Array<Reserve>>([])
    const [ allReserves, setAllReserves ] = useState<Array<Reserve>>([])
    const [ currentReserveIndex, setCurrentReserveIndex ] = useState<number|undefined>(undefined)

    const { setListDraftReserveContext, draftReserve } = useReserveDraftContext()
    const { currentCart } = useCartContext()

    const allPackages = currentCart.event?.packages
    const reserves: Array<Reserve> = []
    const allDraftReserves: Array<ReserveForm> = []

    const saveAllReserves = (items: any) => {
        setAllReserves(items)
        setListDraftReserveContext()
    }

    useEffect(() => {
        if (allReserves.length <= 0) {
            allPackages?.map((pack) => {
                for (let index = 0; index < pack.quantity; index++) {
                    reserves.push({
                        name: "",
                        email: "",
                        phone: "",
                        observation: "",
                        package: {
                            pkPackage: pack.pkPackage,
                            fkAdventure: pack.fkAdventure,
                            price: pack.price,
                            priceWithDiscount: pack.priceWithDiscount,
                            name: pack.name,
                            quantity: pack.quantity
                        }
                    })
                }

                allDraftReserves.push(draftReserve)
            })
            saveAllReserves(reserves)
        }
    }, [allReserves])

    return (
        <ReserveListContextProvider.Provider value={{ setDraftReserveList, draftReserveList, allReserves, currentReserveIndex, selectReserve: setCurrentReserveIndex, updateList: setAllReserves }}>
            {children}
        </ReserveListContextProvider.Provider>
    )
}
