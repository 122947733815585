import React from 'react'
import { FiRepeat } from 'react-icons/fi'
import { Subtitle, Text } from '../../design'
import { Expander } from '../Expander'
import { PhoneField, TextField } from '../Fields'
import { CpfField } from '../Fields/CpfField'

interface Props {
    name: string;
    cpf: string;
    phone: string;
    selected: boolean;
    onToggleSelect: () => void;
    onChange: (field: string, value: string) => void;
    onBlur: (field: string, value: string) => void;
}

export const Pix = ({ selected, onToggleSelect, name, cpf, phone, onChange, onBlur }: Props) : JSX.Element => {
    return (
        <Expander 
            title={
                <>
                    <Subtitle className="pr-5"><FiRepeat /></Subtitle>
                    <Subtitle>PIX</Subtitle>
                </>
            }
            open={selected}
            onToggleOpen={onToggleSelect}
        >
            <>
                <div className="mb-4">
                    <Text small className="pb-2">Seu pagamento será realizado por transferência via PIX</Text>
                    <Text small secondary>Informe os dados do pagante para gerar o código único. Estes dados não serão armazenados ou manipulados.</Text>
                </div>
                <div className="my-4">
                    <TextField required label="Nome completo do pagante" value={name} onChange={(e) => onChange('name', e.target.value)} onBlur={(e) => onBlur('cpf', e.target.value)} />
                </div>
                <div className="my-4">
                    <CpfField required label="CPF do pagante" value={cpf} onChange={(e) => onChange('cpf', e.target.value)} onBlur={(e) => onBlur('cpf', e.target.value)} />
                </div>
                {/* <div className="my-4">
                    <PhoneField label="Celular" required value={phone} onChange={(e) => onChange('phone', e.target.value)} onBlur={(e) => onBlur('phone', e.target.value)} />
                </div> */}
            </>
        </Expander>
    )
}
