import styled, { DefaultTheme } from 'styled-components'

interface ButtonProps {
    primary?: boolean,
    secondary?: boolean,
    outline?: boolean,
    danger?: boolean,
    large?: boolean,
    disabled?: boolean,
    success?: boolean,
    theme: DefaultTheme,
}

const reduceBackgroundColor = (props: ButtonProps) => {
    if(props.outline)
        return props.theme.transparent

    if (props.disabled) {
        return props.theme.disabled
    } else if (props.danger) {
        return props.theme.danger
    } else if (props.primary) {
        return props.theme.main
    } else if (props.secondary) {
        return props.theme.background
    } else if (props.success) {
        return props.theme.success
    } else {
        return props.theme.background
    }
}

const reduceBorderColor = (props: ButtonProps) => {
    if (props.disabled) {
        return props.outline ? props.theme.disabled : props.theme.transparent
    } else if (props.danger) {
        return props.outline ? props.theme.danger : props.theme.transparent
    } else if (props.primary) {
        return props.outline ? props.theme.main : props.theme.main
    } else if (props.secondary) {
        return props.outline ? props.theme.secondary : props.theme.secondary
    } else if (props.success) {
        return props.outline ? props.theme.success : props.theme.success
    } else {
        return props.theme.transparent
    }
}

const reduceColor = (props: ButtonProps) => {
    if (props.disabled) {
        return props.outline ? props.theme.disabled : props.theme.white
    } else if (props.danger) {
        return props.outline ? props.theme.danger : props.theme.white
    } else if (props.primary) {
        return props.outline ? props.theme.main : props.theme.black
    } else if (props.secondary) {
        return props.outline ? props.theme.secondary : props.theme.secondary
    } else if (props.success) {
        return props.outline ? props.theme.success : props.theme.white
    } else {
        return props.theme.white
    }
}

export const Button = styled.button<ButtonProps>`
        padding: 0.5rem 1rem;
        cursor: pointer;
        background: ${reduceBackgroundColor};
        font-weight: bold;
        color: ${reduceColor};
        border: 2px solid ${reduceBorderColor};
        justify-content: center;
        align-items: center;
        width: ${props => props.large ? '100%' : 'auto'};
        display: flex;
        border-radius: 0.3rem;
    `
