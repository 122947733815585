import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { Subtitle, Text } from '../../design'
import { useAuthContext, useCartContext, usePaymentMethodContext, usePaymentReviewContext, useReserveListContext, useStepContext } from '../../../contexts'

import { Api } from '../../../helpers/api'
import { pixReturn } from '../../../helpers/mocks/pixReturn'
import { boletoReturn } from '../../../helpers/mocks/boletoReturn'
import { getJunoToken } from '../../../helpers/constants/JunoToken'
import { creditCardReturn } from '../../../helpers/mocks/creditCardReturn'
import { PIX, BOLETO, CREDIT_CARD, DIRECT, PaymentMethods, PaymentStatus } from '../../../helpers/constants'

import { Payment } from '../../../domain/types'

import { Pix } from '../../components/Pix'
import { Alert } from '../../components/Alert'
import { PageLoader } from '../../components/PageLoader'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const DirectCheckout = window.DirectCheckout

export const PaymentDataStep = () : JSX.Element => {
    const [ loading, setLoading ] = useState(true)
    const [ error, setError ]     = useState(false)

    const { currentUser } = useAuthContext()
    const { currentCart } = useCartContext()
    const { allReserves } = useReserveListContext()
    const { currentStep, advanceStep } = useStepContext()
    const { method, paymentData } = usePaymentMethodContext()
    const { payment, completePayment } = usePaymentReviewContext()

    const checkout = new DirectCheckout(getJunoToken(), true);

    const sendPayment = async (paymentParams: unknown, currentUser?: number|undefined|null) : Promise<any> => {
        try {
            const res = await Api.call('/v1/payment/pay-by-hash', 'POST', paymentParams, currentUser)

            setTimeout(() => {
                if (res.status !== 200)
                    throw new Error('Unable to login with this account')
                
                const respPayment: Payment  = {
                    method: PaymentMethods[method as keyof typeof PaymentMethods],
                    adventure: {
                        reserve_success_message: res.data.data.adventure.reserve_success_message ?? '',
                        url: res.data.data.adventure.url,
                    },
                    boleto_href: res.data.data.boleto_href ? res.data.data.boleto_href : '',
                    boleto_code: res.data.data.payment.boleto_code ? res.data.data.payment.boleto_code : '',
                    qrcode_img: res.data.data.payment.qrcode_img,
                    pix_code: res.data.data.payment.pix_code,
                    payment_status: PaymentStatus[res.data.data.payment_status as keyof typeof PaymentStatus],
                    price: res.data.data.payment.price,
                    request: {
                        short_link: res.data.data.request.short_url
                    },
                    reserves: allReserves
                }
                
                if (method === PaymentMethods[BOLETO] || method === PaymentMethods[CREDIT_CARD] || method === "LOCAL") {
                    advanceStep()
                } else {
                    const statusInterval = setInterval(() => {
                        Api.call('/v1/payment/check-status-by-id', 'GET', { payment_id: res.data.data.payment_id })
                        .then(({ data }) => {
                            if(data.data.status === PaymentStatus.CONFIRMED){
                                advanceStep();
                                clearInterval(statusInterval);
                            }
                        })
                    }, 6000)
                }
                
                completePayment(respPayment)
                setLoading(false)
            },2000)   
        } catch (error) {
            console.log('ERROR ', error)
            setLoading(false)
            setError(true)
        }        
    }

    useEffect(() => {
        console.log('METHOD ', method)
        if (method === CREDIT_CARD && paymentData.cardNumber) {
            if (checkout.isValidCardNumber(paymentData.cardNumber.replace(/\D+/gi, ""))) {
                if (!paymentData.cardNumber || !paymentData.cardHolder || !paymentData.cardHolder || !paymentData.cardCvv || !paymentData.cardValidDate) {
                    return
                }

                const cardData = {
                    cardNumber: paymentData.cardNumber.replace(/\D+/gi, ""),
                    holderName: paymentData.cardHolder,
                    securityCode: paymentData.cardCvv,
                    expirationMonth: paymentData.cardValidDate.split("/")[0],
                    expirationYear: moment(paymentData.cardValidDate.split("/")[1], "YY").format("YYYY")
                };

                checkout.getCardHash(cardData, function(cardHash: string) {
                    const paramsCreditCard = {
                        name: paymentData.cardHolder,
                        package: currentCart.event?.packages,
                        reserves: allReserves,
                        widget: true,
                        date: currentCart.event?.date.substr(0, 10),
                        fkAdventure: currentCart.adventure.pk_adventure,
                        isRequest: true,
                        pk_cart: currentCart.pk_cart,
                        pk_request: currentCart.pk_request,
                        hour: currentCart.event?.hour,
                        paymentMethod: method,
                        paymentData,
                        interest: 0,
                        installment: 1,
                        hash: cardHash,
                        ...paymentData
                    }

                    sendPayment(paramsCreditCard, currentUser)
                }, function(error: string) {
                    setError(true)
                });
            }
        } else if (method === BOLETO) {
            const paramsBoleto = {
                package: currentCart.event?.packages,
                reserves: allReserves,
                widget: true,
                date: currentCart.event?.date.substr(0, 10),
                fkAdventure: currentCart.adventure.pk_adventure,
                isRequest: true,
                pk_cart: currentCart.pk_cart,
                pk_request: currentCart.pk_request,
                hour: currentCart.event?.hour,
                paymentMethod: method,
                paymentData,
                interest: 0,
                installment: 1,
                ...paymentData
            }

            sendPayment(paramsBoleto, currentUser)
        } else if (method === PIX) {
            const paramsPix = {
                package: currentCart.event?.packages,
                reserves: allReserves,
                widget: true,
                date: currentCart.event?.date.substr(0, 10),
                fkAdventure: currentCart.adventure.pk_adventure,
                isRequest: true,
                pk_cart: currentCart.pk_cart,
                pk_request: currentCart.pk_request,
                hour: currentCart.event?.hour,
                paymentMethod: method,
                paymentData,
                interest: 0,
                installment: 1,
                ...paymentData
            }

            sendPayment(paramsPix, currentUser)
        } else if (method === DIRECT) {
            const paramsDirect = {
                package: currentCart.event?.packages,
                reserves: allReserves,
                widget: true,
                date: currentCart.event?.date.substr(0, 10),
                fkAdventure: currentCart.adventure.pk_adventure,
                isRequest: true,
                pk_cart: currentCart.pk_cart,
                pk_request: currentCart.pk_request,
                hour: currentCart.event?.hour,
                paymentMethod: method,
                paymentData,
                interest: 0,
                installment: 1,
                ...paymentData
            }

            sendPayment(paramsDirect, currentUser)
        }
        
    }, [])

    return (
        <>
            <PageLoader loading={loading} />
            {!loading && !error &&
                <>
                    <div className="pt-0 pb-2 px-2">
                        <div className="mb-4 text-center">
                            {payment?.qrcode_img && payment?.pix_code  &&
                                <Pix price={payment?.price} pix_code={payment?.pix_code} qrcode_img={payment?.qrcode_img}></Pix>   
                            }
                        </div>
                        <hr />
                    </div>
                </>
            }
            {error &&
                <Alert
                    color="red" 
                    message={{title: 'Erro', description: 'Tivemos problemas para processar o seu pagamento...'}}
                />
            }
        </>
    )
}