import moment from "moment";

export const isValidEmail = (value: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value.toLowerCase());
}

export const isValidPhone = (value: string): boolean => {
    const re = /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/
    return re.test(value);
}

export const isValidDate = (value: string, format?: string): boolean => {
    return moment(value, format).isValid()
}

export const isNotEmpty = (value: string): boolean => {
    return !!value
}

export const isValidOption = (value: unknown, options: Array<unknown>): boolean => {
    return options.includes(value)
}

export const isValidNumeric = (value?: string | number, format?: string): boolean => {
    if (!value)
        return false

    if (typeof value === "string"){
        if (Number.isNaN(value.replace(/\D/g, "")))
            return false

        if (format !== undefined && !(new RegExp(format.replace(/#/g, "[0-9]"))).test(value))
            return false
    }
    
    if (typeof value === "number" && Number.isNaN(value))
        return false

    return true
}