import styled from 'styled-components'

interface DividerProps {
    top?: boolean,
    bottom?: boolean,
}
export const Divider = styled.div<DividerProps>`
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
    height: 0;
    top: ${props => props.top ? 0 : 'unset'};
    bottom: ${props => props.bottom ? 0 : 'unset'};
    border-image-slice: 1;
    border-image-source: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAApoAAAAECAYAAAAu/5/lAAAA30lEQVRoBe2YSwqAMAxEe1Rv7qY5h1LXwUxCKIhPcGPjp2TedOwws6twnmPjMec8Ct9Ymddzz3rfxukNMzs3zo/eNTaX3pX8w2UT7vqEiWf26XIx3teZ+En0jt6peeArnrlCjmv6wXXAi/1CriCslDTo6vYr4AV8uXNjwZORkgrhDu5EDlnvJKK0Irj7H3cETSdoE1Y0w1Cq+DvvMxWCpqI4vYYFr0+beKauu6gSz+zTJZ4ZqS03XvVMgiZBM6e0ZDWmiWmKu0YXYSUJ10s53MEd3D0aYDf6xSeyQ9WgeQOWdYaOPxLLuwAAAABJRU5ErkJggg==");
    border-style: solid;
    border-top-width: 3px;
    border-bottom-width: 0px;
    &::after{
        content: '';
        position: absolute;
        background: #ccc;
        display: inline-block;
        border-bottom-right-radius: 12px;
        border-top-right-radius: 12px;
        height: 12px;
        width: 7px;
        left: 0;
        bottom: 50%;
        transform: translateY(4px);
    }
    &::before{
        content: '';
        position: absolute;
        background: #ccc;
        display: inline-block;
        border-bottom-left-radius: 12px;
        border-top-left-radius: 12px;
        height: 12px;
        width: 7px;
        right: 0;
        bottom: 50%;
        transform: translateY(4px);
    }
`