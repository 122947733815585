import { Api } from "../helpers/api"

export const getCartByHash = async (hash: string | undefined, auth_key?: number | null): Promise<any> => {
    const res = await Api.call('/v3/cart/get-cart', 'POST', { hash }, auth_key)

    if (res.status !== 200)
        return false

    return res.data
}

export const setAsCartOwner = async (hash: string | undefined, auth_key?: number | null): Promise<any> => {
    const res = await Api.call('/v3/cart/set-cart-owner', 'POST', { hash }, auth_key)

    if (res.status !== 200)
        return false

    return res.data
}

export const retrieveCheckoutInformation = async (fk_cart: number, auth_key?: number | null): Promise<any> => {
    const res = await Api.call('/v3/cart/retrieve-checkout-information', 'POST', { fk_cart }, auth_key)

    if (res.status !== 200)
        return false

    return res.data
}

export const updateCheckoutStep = async (dataCheckoutStep: unknown, auth_key?: number | null): Promise<any> => {
    const res = await Api.call('/v3/cart/checkout-step', 'POST', dataCheckoutStep, auth_key)

    if (res.status !== 200)
        return false

    return res.data
}

export const updateAccessedAndExpiredTime = async (data: unknown, auth_key?: number | null): Promise<any> => {
    const res = await Api.call('/v3/cart/accessed-checkout', 'POST', data, auth_key)

    if (res.status !== 200)
        return false

    return res.data
}

export const getSuccessMessage = async (fk_adventure: unknown, auth_key?: number | null): Promise<any> => {
    const res = await Api.call('/v3/cart/success', 'GET', { fk_adventure }, auth_key)

    if (res.status !== 200)
        return false

    return res.data
}