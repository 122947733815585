import React from 'react'
import styled from 'styled-components'
import { FiLock } from 'react-icons/fi'
import LogoAdrena from '../../assets/images/logo-adrena-white.png'

interface Props {
    children: JSX.Element | Array<JSX.Element>
}

const ModalContainer = styled.div`
    background: ${props => props.theme.background};
    position: absolute;
    margin: auto;
    padding: 0;
    border-radius: 10px;
    max-width: 370px;
    @media(max-width: 369px) {
        max-width: 90vw;
    }
    
    top: 5vh;
    left: 0;
    right: 0;
    &:after {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: -5vh;
        height: 5vh;
    }
`


const Topping = styled.div`
    width: 100%;
    background: #333;
    justify-content: center;
    position: relative;
    display: flex;
    color: ${props => props.theme.white};
    padding: 5px 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`

const Logo = styled.img`
    height: 15px;
    width: auto;
    margin: auto;
`


const IconHolder = styled.div`
    position: absolute;
    color: ${props => props.theme.white};
    right: 10px;
    padding: 0;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
`

const Background = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    
`


export const Modal = ({ children } : Props) : JSX.Element => {
    return (
        <Background>
            <ModalContainer>
                <Topping>
                    <Logo src={LogoAdrena} alt="Adrena.me" />
                    <IconHolder>
                        <FiLock size={16} />
                    </IconHolder>
                </Topping>
                {children}
            </ModalContainer>
        </Background>
    )
}
