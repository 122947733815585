
// export const mockInstallments = [
//     {
//         label: "1x de R$25,00 (sem juros)",
//         value: 1
//     },
//     {
//         label: "2x de R$25,00 (sem juros)",
//         value: 2
//     },
//     {
//         label: "3x de R$25,00 (sem juros)",
//         value: 3
//     },
// ]

export const mockInstallments = [
    {
        label: "1x R$VA (sem juros)",
        value: 0,
        times: 1,
        selected: true
    },
    {
        label: "2x R$PA = R$VA",
        value: 2.5,
        times: 2,
        selected: false
    },
    {
        label: "3x R$PA = R$VA",
        value: 3.5,
        times: 3,
        selected: false
    },
    {
        label: "4x R$PA = R$VA",
        value: 4.5,
        times: 4,
        selected: false
    },
    {
        label: "5x R$PA = R$VA",
        value: 5.5,
        times: 5,
        selected: false,
    },
    {
        label: "6x R$PA = R$VA",
        value: 6.5,
        times: 6,
        selected: false,
    },
    {
        label: "7x R$PA = R$VA",
        value: 7.5,
        times: 7,
        selected: false,
    },
    {
        label: "8x R$PA = R$VA",
        value: 8.5,
        times: 8,
        selected: false,
    },
    {
        label: "9x R$PA = R$VA",
        value: 9.5,
        times: 9,
        selected: false,
    },
    {
        label: "10x R$PA = R$VA",
        value: 10.5,
        times: 10,
        selected: false,
    },
    {
        label: "11x R$PA = R$VA",
        value: 11.5,
        times: 11,
        selected: false,
    },
    {
        label: "12x R$PA = R$VA",
        value: 12.5,
        times: 12,
        selected: false
    }
]