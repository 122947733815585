import React from "react"
import { FiLogIn } from "react-icons/fi"
import { useAuthContext } from "../../../../contexts"
import { registerAccountByEmail } from "../../../../services/authService"
import { PasswordField, PhoneField, TextField } from "../../../components/Fields"
import { Link, Checkbox, Title, Label, Text, Button } from "../../../design"
import { useNavigate } from "react-router-dom"

export const FormRegisterEmail: React.FC = () => {
    const { authData, changeAuthData, setUserAuthKey, isStepComplete } = useAuthContext()
    const push = useNavigate()

    const onRegister = async () => {
        const newUser = {
            email: authData.email!,
            password: authData.password!,
            name: authData.name!,
            phone: authData.phone!
        }
        try {
            const user = await registerAccountByEmail(newUser)
            setUserAuthKey(user.auth_key)
            push("/")
        } catch (error) {
            console.error(error)
        }
        
    }

    return (
        <>
            <div className="my-4">
                <FiLogIn size={50} />
            </div>
            <div className="my-4">
                <Title primary>Preencha os dados de cadastro</Title>
            </div>
            <div className="my-4 w-full">
                <Label>Email</Label>
                <Text>{authData.email}</Text>
            </div>
            <div className="my-2 w-full">
                <TextField 
                    value={authData.name}
                    onChange={(e) => changeAuthData(e.target.value, "name")}
                    label="Nome" 
                    required 
                    placeholder="Fulano da Silva" 
                />
            </div>
            <div className="my-2 w-full">
                <PhoneField 
                    value={authData.phone}
                    onChange={(e) => changeAuthData(e.target.value, "phone")}
                    label="Celular" 
                    required 
                    placeholder="(99) 99999-9999" 
                />
            </div>
            <div className="my-2 w-full">
                <PasswordField 
                    value={authData.password}
                    onChange={(e) => changeAuthData(e.target.value, "password")}
                    label="Senha" 
                    required 
                    placeholder="***********" 
                />
            </div>
            <div className="my-2 w-full">
                <PasswordField 
                    value={authData.confirmPassword}
                    onChange={(e) => changeAuthData(e.target.value, "confirmPassword")}
                    label="Confrimar senha" 
                    required 
                    placeholder="***********" 
                />
            </div>
            <div className="my-2 w-full">
                <label>
                    <Checkbox 
                        checked={authData.privacyChecked} 
                        onChange={(e) => changeAuthData(e.target.checked, "privacyChecked")}
                    /> 
                    Os dados aqui inseridos são verdadeiros e concordo com a <Link href="#">política de privacidade de dados</Link> de adrena.me.
                </label>
            </div>
            <div className="mt-2 w-full">
                <Button 
                    disabled={!isStepComplete} 
                    onClick={onRegister}
                    large 
                    success
                >
                    Concluir e entrar
                </Button>
            </div>
        </>
    )
}