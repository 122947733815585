import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';
import { Main } from './ui/pages/Main';
import { defaultTheme, GlobalStyle } from './ui/design/style';
import { StepsContext, ReserveDraftContext, ReserveListContext, PaymentReviewContext, PaymentMethodContext, AuthContext } from './contexts';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Login } from './ui/pages/Login';
import { PrivateRoute } from './ui/components/PrivateRoute';
import { CartContext } from './contexts/CartContext';
import { Auth } from './services';

ReactDOM.render(
  <React.StrictMode>
    <Router>
    <AuthContext>
      <CartContext>
        <PaymentReviewContext>
          <PaymentMethodContext>
            <ReserveListContext>
              <ReserveDraftContext>
                <StepsContext>
                  <GlobalStyle />
                  <ThemeProvider theme={defaultTheme}>
                    <Routes>
                      <React.Fragment>
                        <Route path="/:hash" element={<Main />} />
                        <Route path="/login" element={<Login />} />
                      </React.Fragment>
                    </Routes>
                  </ThemeProvider>    
                </StepsContext>
              </ReserveDraftContext>
            </ReserveListContext>
          </PaymentMethodContext>
        </PaymentReviewContext>
      </CartContext>
      </AuthContext>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
