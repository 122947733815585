import React from 'react'
import styled from 'styled-components'

interface ProgressProps {
    steps: number,
    progress: number,
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
interface SteperProps {
    completed?: boolean
}

const Steper = styled.span<SteperProps>`
    height: 15px;
    width: 15px;
    background: ${props => props.completed ? props.theme.success : '#BEBEBE'};
    border-radius: 50%;
    margin: 10px 5px;
`

interface ConectorProps {
    completed?: boolean
}

const Conector = styled.span<ConectorProps>`
    height: 0;
    border-top: 4px solid ${props => props.completed ? props.theme.success : '#BEBEBE'};
    border-radius: 2px;
    flex: 1;
`

export const ProgressBar = ({ steps, progress }: ProgressProps) : JSX.Element => {
    const progressArray : Array<JSX.Element>  = []
    for (let index = 1; index <= steps; index++) {
        const completed = index <= progress
        if(index > 1){
            progressArray.push(( <Conector key={`conector-${index}`} completed={completed} /> ))
        }
        progressArray.push(( <Steper key={`steper-${index}`} completed={completed} />))
    }

    return (
        <Container>
            {progressArray}
        </Container>
    )
}
