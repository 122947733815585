import moment from "moment"
import { BRAZIL_UFS } from "../helpers/constants"

export const isValidPayerName = (name?: string) : boolean => {
    if (!name) 
        return false

    // Name has no last name
    if (name.split(" ").filter(val => !!val).length < 2) 
        return false

    // First name 3 chars and last name 3 chars
    if (name.length < 7) 
        return false

    return true
}

export const isValidPayerCPF = (cpf?: string) : boolean => {
    if (!cpf) 
        return false

    cpf = cpf.replace(/\D/g,'')

    let Soma = 0
    let Resto: number

    if (cpf === "00000000000") 
        return false

    for (let i = 1; i <= 9; i++) 
        Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)

    Resto = (Soma * 10) % 11

    if ((Resto === 10) || (Resto === 11)) 
        Resto = 0

    if (Resto !== parseInt(cpf.substring(9, 10))) 
        return false

    Soma = 0;

    for (let i = 1; i <= 10; i++) 
        Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)

    Resto = (Soma * 10) % 11

    if ((Resto === 10) || (Resto === 11)) 
        Resto = 0

    if (Resto !== parseInt(cpf.substring(10, 11))) 
        return false

    return true
}

export const isValidCardNumber = (number?: string) : boolean => {
    if (!number)
        return false

    if (number.length !== 16)
        return false

    return true
}

export const isValidCardCvv = (cvv?: string) : boolean => {
    if(!cvv)
        return false

    if(cvv.length !== 3)
        return false
    
    return true
}

export const isValidCardValidDate = (validDate?: string) : boolean => {
    if (!validDate)
        return false

    const formattedDate = validDate.split("/").reverse().join("-")
    
    if (moment(`${formattedDate}-01`).isBefore(moment(), 'month'))
        return false

    return true
}

export const isValidCardInstallments = (installment?: number) : boolean =>  {
    if (!installment)
        return false

    if (installment < 1 || installment > 12)
        return false

    return true
}

export const isValidBirthday = (birthday?: string) : boolean => {
    if (!birthday)
        return false

    const formattedDate = birthday.split("/").reverse().join("-")

    if (moment(formattedDate).isSameOrAfter(moment()))
        return false

    return true
}

export const isValidCEP = (cep?: string) : boolean => {
    if (!cep)
        return false

    if (cep.length !== 8)
        return false

    return true
}

export const isValidStreet = (street?: string) : boolean => {
    if (!street)
        return false

    if (street.length <= 3)
        return false

    return true
}

export const isValidNumber = (number?: string) : boolean => {
    if (!number)
        return false

    return true
}

export const isValidDistrict = (district?: string) : boolean => {
    if (!district)
        return false

    return true
}

export const isValidCity = (city?: string): boolean => {
    if (!city)
        return false

    if (city.length < 3)
        return false

    return true
}

export const isValidUF = (uf?: string): boolean => {
    if (!uf)
        return false

    if (!BRAZIL_UFS.includes(uf))
        return false

    return true
}