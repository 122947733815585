import React from 'react'
import { Expander } from '../Expander'
import { FiX, FiHome } from 'react-icons/fi'
import { Subtitle, Text } from '../../design'

interface Props {
    selected: boolean;
    localTitle: string;
    localMessage: string;
    onToggleSelect: () => void;
}

export const Direct = ({ selected, onToggleSelect, localMessage, localTitle }: Props): JSX.Element => {
    return (
        <Expander
            title={
                <>
                    <Subtitle className="pr-5"><FiHome /></Subtitle>
                    <Subtitle>Pagamento direto</Subtitle>
                </>
            }
            open={selected}
            onToggleOpen={onToggleSelect}
        >
            <>
                <div className="mb-4">
                    <Text small className="pb-2">{localTitle}</Text>
                    {localMessage.split("\r\n").map((text, index) => 
                        <Text key={`message-${index}`} small secondary>{text}</Text>
                    )}
                </div>
            </>
        </Expander>
    )
}
