import React from 'react'
import { Label } from '../../design'
import { CepField, NumericField, TextField } from '../Fields'

export type AddressValues = {
    cep?: string;
    street?: string;
    number?: string;
    city?: string;
    district?: string;
    complement?: string;
    uf?: string;
}

interface Props {
    title: string;
    onChange: (field: string, value: string) => void;
    onBlur: (field: string, value: string) => void;
    values: AddressValues;
}

export const AddressForm = ({ title, onChange, onBlur, values }: Props): JSX.Element => {
    return (
        <div className="mb-2">
            <div className="flex flex-row mt-4 mb-2">
                <Label bold>{title}</Label>
            </div>
            <div className="flex flex-row my-2">
                <div className="flex-1">
                    <CepField label="CEP" required value={values.cep} onChange={(e) => onChange('cep', e.target.value)} onBlur={(e) => onBlur('cep', e.target.value)} />
                </div>
            </div>
            <div className="flex flex-row my-2">
                <div className="flex-initial mr-3">
                    <div>
                        <TextField label="Rua" required value={values.street} onChange={(e) => onChange('street', e.target.value)} onBlur={(e) => onBlur('street', e.target.value)} />
                    </div>
                </div>
                <div className="flex-1">
                    <div>
                        <NumericField label="Número" required value={values.number} onChange={(e) => onChange('number', e.target.value)} onBlur={(e) => onBlur('number', e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="flex flex-row my-2">
                <div className="flex-1">
                    <TextField label="Complemento" required value={values.complement} onChange={(e) => onChange('complement', e.target.value)} onBlur={(e) => onBlur('complement', e.target.value)} />
                </div>
            </div>
            <div className="flex flex-row my-2">
                <div className="flex-1">
                    <TextField label="Bairro" required value={values.district} onChange={(e) => onChange('district', e.target.value)} onBlur={(e) => onBlur('district', e.target.value)} />
                </div>
            </div>
            <div className="flex flex-row my-2">
                <div className="flex-initial mr-3">
                    <div>
                        <TextField label="Cidade" required value={values.city} onChange={(e) => onChange('city', e.target.value)} onBlur={(e) => onBlur('city', e.target.value)} />
                    </div>
                </div>
                <div className="flex-1">
                    <div>
                        <TextField label="UF" required value={values.uf} onChange={(e) => onChange('uf', e.target.value.toUpperCase())} onBlur={(e) => onBlur('uf', e.target.value.toUpperCase())} />
                    </div>
                </div>
            </div>
        </div>
    )
}
