export const BRAZIL_UFS = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
    'DF'
];

export const PIX = 'PIX'
export const CREDIT_CARD = 'CREDIT_CARD'
export const BOLETO = 'BOLETO'
export const DIRECT = 'LOCAL'
export const PAGSEGURO = 'PAGSEGURO'
export const MERCADO_PAGO = 'MERCADO_PAGO'

export enum PaymentMethods {
    PIX = 'PIX',
    CREDIT_CARD = 'CREDIT_CARD',
    BOLETO = 'BOLETO',
    DIRECT = 'LOCAL',
    PAGSEGURO = 'PAGSEGURO',
    MERCADO_PAGO = 'MERCADO_PAGO'
}

export enum PaymentStatus {
    CONFIRMED = 'CONFIRMED',
    WAITING = 'WAITING',
    CANCELED = 'CANCELED'
}

export enum LoginSteps {
    SELECT_EMAIL = 'SELECT_EMAIL',
    EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
    ENTER_PASSWORD = 'ENTER_PASSWORD',
    REGISTER_NEW_ACCOUNT = 'REGISTER_NEW_ACCOUNT',
    ENTER_SECURITY_CODE = 'ENTER_SECURITY_CODE'
}

export enum UserType {
    REGISTRED = 'REGISTRED',
    NOT_REGISTRED = 'NOT_REGISTRED',
    REGISTRED_WITHOUT_PASSWORD = 'REGISTRED_WITHOUT_PASSWORD'
}