import React from 'react'
import { FiCreditCard, FiFileText, FiRepeat, FiX } from "react-icons/fi"
import { BOLETO, CREDIT_CARD, DIRECT, MERCADO_PAGO, PAGSEGURO, PIX } from "."

export const CONTINUE = 'Continuar'

export const PAYMENT_METHOD_LABEL = {
    [PIX]: "Pix",
    [CREDIT_CARD]: "Cartão de crédito",
    [BOLETO]: "Boleto",
    [DIRECT]: "Pagamento direto",
    [PAGSEGURO]: "PagSeguro",
    [MERCADO_PAGO]: "Mercado Pago"
}

export const PAYMENT_METHOD_ICON = {
    [PIX]: <FiRepeat />,
    [CREDIT_CARD]: <FiCreditCard />,
    [BOLETO]: <FiFileText />,
    [DIRECT]: <FiX />,
    [PAGSEGURO]: <FiX />,
    [MERCADO_PAGO]: <FiX />
}

export const PAYMENT_STATUS_LABEL = {
    "CONFIRMED": "Confirmado",
    "WAITING": "Aguardando",
    "CANCELED": "Cancelado"
}