import React, { useMemo } from 'react'
import { useAuthContext } from '../../../contexts'
import { LoginSteps } from '../../../helpers/constants'
import { FormChooseRegister } from './FormChooseRegister'
import { FormPassword } from './FormPassword'
import { FormSelectEmail } from './FormSelectEmail'
import { FormRegisterEmail } from './FormRegisterEmail'


export const EmailAuthentication = (): JSX.Element => {
    const { step } = useAuthContext()

    const FormComponent = useMemo(() => {
        switch (step) {
            case LoginSteps.SELECT_EMAIL:
                return <FormSelectEmail />
            case LoginSteps.REGISTER_NEW_ACCOUNT:
                return <FormRegisterEmail />
            case LoginSteps.ENTER_PASSWORD:
                return <FormPassword />
            case LoginSteps.EMAIL_NOT_FOUND:
                return <FormChooseRegister />
            default:
                return <></>
        }
    }, [step])

    return (
        <>
            <div className="flex flex-col items-center">
                {FormComponent}
            </div>
        </>
    )
}

