import React from 'react'
import { Subtitle } from '../../design'
import { ReserveExpander } from '../../components/ReserveExpander'
import { useReserveListContext } from '../../../contexts'

export const ReserveDataStep = () : JSX.Element => {
    const { allReserves, currentReserveIndex, selectReserve } = useReserveListContext()

    return (
        <div className="pt-0 pb-2 px-2">
            <div className="mb-4 text-center">
                <Subtitle primary>Preencha os dados do(s) ingresso(s)</Subtitle>
            </div>
            <hr />
            {allReserves.map((reserve, index) => 
                <ReserveExpander 
                    key={`reserve-${index}`} 
                    pos={index + 1} 
                    active={index === currentReserveIndex} 
                    onToggle={() => selectReserve(index === currentReserveIndex ? undefined : index)} 
                    reserve={reserve}
                />
            )}
        </div>
    )
}
