import React, { createContext, useContext, useState, useMemo } from "react"
import { usePaymentReviewContext, useReserveListContext, useReserveDraftContext } from ".";
import { ReserveForm } from "../domain/types";
import { CONTINUE, PIX, DIRECT } from "../helpers/constants";
import { isReserveCompleted } from "../validation";
import { usePaymentMethodContext } from "./PaymentMethodContext";

const TOTAL_STEPS = 4

interface StepContextValue {
    currentStep: number;
    totalSteps: number;
    isReadyToContinue: boolean;
    nextStepLabel: string;
    advanceStep:() => void;
    backStep:() => void;
    setUserCheckoutStep:(value: number) => void;
}

type MockableValues = {
    currentStep?: number;
}

const StepsContextProvider = createContext<StepContextValue>({
    currentStep: 1,
    totalSteps: TOTAL_STEPS,
    isReadyToContinue: false,
    nextStepLabel: CONTINUE,
    advanceStep: () => undefined,
    backStep: () => undefined,
    setUserCheckoutStep: () => undefined
})

export const useStepContext = () : StepContextValue => useContext(StepsContextProvider); 

interface StepContextProps {
    children: JSX.Element | Array<JSX.Element>,
    mock?: MockableValues
}

export const StepsContext = ({ children, mock }: StepContextProps ) : JSX.Element => {
    const [ currentStep, setCurrentStep ] = useState(mock?.currentStep || 1)
    const { allReserves } = useReserveListContext()
    const { method, dataCompleted } = usePaymentMethodContext()
    const { payment, completePayment } = usePaymentReviewContext()
    const { updateLastUserCheckoutStep } = useReserveDraftContext()

    const setUserCheckoutStep = (step: number) => {
        setCurrentStep(step < TOTAL_STEPS ? step : 1)
    }

    const advanceStep = () => {
        if (!payment && currentStep === 2) {
            // completePayment()
        }

        if (!!payment && currentStep === 4) {
            window.open(payment.request?.short_link || payment.adventure.url, "_blank")
        }

        setCurrentStep(currentStep < TOTAL_STEPS ? currentStep + 1 : currentStep)
        updateLastUserCheckoutStep('step', true)
    } 
    
    const backStep = () => setCurrentStep(currentStep > 1 ? currentStep - 1 : currentStep)

    const getNextStepLabelByPaymentMethod = () : string => {
        switch (method) {
            case PIX:
                return "Gerar código PIX";
            case DIRECT:
                return "Concluir reserva";
            default:
                return CONTINUE;
        }
    }

    const isReadyToContinue = useMemo<boolean>(() => {
        switch (currentStep) {
            case 1:
                return allReserves.reduce<boolean>((allCompleted, reserve) => isReserveCompleted(reserve as ReserveForm) && allCompleted, true)
            case 2:
                return dataCompleted
            case 3:
                return !!payment
            case 4:
                return true
            default:
                return false
        }
    }, [allReserves, currentStep, dataCompleted, payment])

    const nextStepLabel = useMemo<string>(() => {
        switch (currentStep) {
            case 1:
                return CONTINUE
            case 2:
                return getNextStepLabelByPaymentMethod()
            case 3:
                return "Concluir"
            case 4:
                return payment?.request ? "Ir para tela do evento" : "Ir para tela da experiência"
            default:
                return CONTINUE;
        }
    }, [currentStep, method, payment])

    return (
        <StepsContextProvider.Provider value={{ currentStep, isReadyToContinue, nextStepLabel, advanceStep, backStep, totalSteps: TOTAL_STEPS, setUserCheckoutStep }}>
            {children}
        </StepsContextProvider.Provider>
    )
}
