import React, { createContext, useContext, useState } from "react"
import { Payment } from "../domain/types";
import { mockSuccessPayment } from "../helpers/mocks/payment";

interface PaymentReviewContextValue {
    payment?: Payment;
    completePayment: (value: Payment) => void;
}


const PaymentReviewProvider = createContext<PaymentReviewContextValue>({
    completePayment: () => undefined,
})

export const usePaymentReviewContext = () : PaymentReviewContextValue => useContext(PaymentReviewProvider); 

interface PaymentReviewContextProps {
    children: JSX.Element | Array<JSX.Element>,
    mock?: {
        payment?: Payment
    }
}

export const PaymentReviewContext = ({ children, mock }: PaymentReviewContextProps ) : JSX.Element => {
    const [ payment, setPayment ] = useState(mock?.payment)

    const completePayment = (value: Payment) => {
        // setTimeout(() => {
            setPayment(value)
        // },1000)
    }

    return (
        <PaymentReviewProvider.Provider value={{ payment, completePayment }}>
            {children}
        </PaymentReviewProvider.Provider>
    )
}
