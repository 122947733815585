import React from 'react'
import ContentLoader from 'react-content-loader'

export const FooterLoader = (): JSX.Element => {
  return (
    <>
    <ContentLoader 
      speed={0.8} 
      width={342}
      height={80}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="34" rx="5" ry="5" width="100" height="40" />
      <rect x="240" y="34" rx="5" ry="5" width="100" height="40" />
    </ContentLoader>
    </>
  )
}