import React from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

interface Props {
    title: JSX.Element | Array<JSX.Element> | string;
    subtitle?: JSX.Element | Array<JSX.Element> | string;
    children: JSX.Element | Array<JSX.Element> | string;
    open: boolean;
    onToggleOpen: () => void;
    
}

export const Expander = ({ title, subtitle, children, open, onToggleOpen }: Props) : JSX.Element => {
    return (
        <div>
            <div className="cursor-pointer" onClick={onToggleOpen}>
                <div className="flex mb-3 flex-row justify-between align-items-center">
                    <div className="flex flex-row">
                        {title}
                    </div>
                    {open ? <FiChevronUp /> : <FiChevronDown />}
                </div>
                <div className="flex justify-between align-items-baseline mb-2">
                    {subtitle}
                </div>
            </div>
            {open && children}
        </div>
    )
}
