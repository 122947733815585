import 'styled-components'
import { createGlobalStyle, DefaultTheme } from "styled-components"
import './index.css'

declare module 'styled-components' {
    export interface DefaultTheme {
        main: string,
        background: string,
        black: string,
        white: string,
        warning: string,
        secondary: string,
        disabled: string,
        transparent: string,
        success: string,
        danger: string
    }
  }

export const defaultTheme : DefaultTheme = {
    main: '#F07D00',
    danger: '#c91414',
    warning: '#F3D95E',
    background: '#FFFFFF',
    black: '#333',
    white: '#fff',
    secondary: '#707070',
    disabled: '#DDDDDD',
    transparent: 'transparent',
    success: '#39C96F',
}

export const GlobalStyle = createGlobalStyle`
    body{

        background: rgba(0,0,0,0.3);
        font-family: 'Cabin', sans-serif;
    }
    hr {
        border: none;
        height: 0px;
        border-top: 1px solid #DDDDDD;
    }
`