import React from 'react'
import { Expander } from '../Expander'
import { FiX } from 'react-icons/fi'
import { Subtitle, Text } from '../../design'

interface Props {
    selected: boolean;
    onToggleSelect: () => void;
}

export const PagSeguro = ({ selected, onToggleSelect }: Props): JSX.Element => {
    return (
        <Expander
            title={
                <>
                    <Subtitle className="pr-5"><FiX /></Subtitle>
                    <Subtitle>PagSeguro</Subtitle>
                </>
            }
            open={selected}
            onToggleOpen={onToggleSelect}
        >
            <>
                <div className="mb-4">
                    <Text small className="pb-2">Seu pagamento será relizado via PagSeguro</Text> 
                    <Text small secondary>Após concluir o pagamento, clique em <strong>voltar para a loja</strong> para concluir o seu pedido</Text>
                </div>
            </>
        </Expander>
    )
}
