import React, { ChangeEventHandler, useMemo, useState } from 'react'
import { isNotEmpty } from '../../../validation'
import { Label, Text } from '../../design'
import { Input } from '../../design/Input'

interface Props {
    label?: string;
    placeholder?: string;
    required?: boolean;
    error?: string;
    inputProps?:  React.InputHTMLAttributes<HTMLInputElement>;
    value?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}
  

export const PasswordField = ({label, placeholder, required, error, inputProps, value, onChange}: Props) : JSX.Element => {
    const [didInput, setDidInput] = useState(false)
    const errorMessage = useMemo(() => {
        if(error) return error

        if(!didInput) return

        if(required && !(value && isNotEmpty(value)))
            return "Campo obrigatório"

    }, [required, value, error])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDidInput(true)

        if(onChange)
            onChange(e)
    }

    return (
        <>
            {label && <Label danger={!!errorMessage}>{label}</Label>}
            <Input {...inputProps} type="password" value={value} error={!!errorMessage} onChange={handleChange} placeholder={placeholder} />
            {!!errorMessage && <Text danger>{errorMessage}</Text>}
        </>
    )
}
