import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Header } from '../../components/Header'
import { ReviewStep } from './ReviewStep'
import { ReserveDataStep } from './ReserveDataStep'
import { PaymentDataStep } from './PaymentDataStep'
import { PaymentMethodStep } from './PaymentMethodStep'
import { useAuthContext, usePaymentMethodContext, useReserveDraftContext, useReserveListContext, useStepContext } from '../../../contexts'
import { Modal, ModalHeader, ModalFooter, ModalBody } from '../../components/Modal'
import { Api } from '../../../helpers/api'
import { PageLoader } from '../../components/PageLoader'
import { useCartContext } from '../../../contexts/CartContext'
import { Footer } from '../../components/Footer'
import { Alert } from '../../components/Alert'
import { retrieveCheckoutInformation } from '../../../services/cartService'

export const Main = (): JSX.Element => {
    const push = useNavigate()

    const { currentUser } = useAuthContext()
    const { setCartContext, isExpiredCheckout } = useCartContext()
    const { currentStep, setUserCheckoutStep, advanceStep } = useStepContext()
    const { updateList } = useReserveListContext()
    const { updatePaymentData } = usePaymentMethodContext()

    if (currentUser === null)
        push("/login", { replace: true })

    const [error, setError] = useState<string>('');
    const [showCheckout, setShowCheckout] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const { hash } = useParams();

    useEffect(() => {
        Api.call('/v3/cart/get-cart', 'POST', { hash }, currentUser)
            .then(cart => {
                Api.call('/v3/cart/retrieve-checkout-information', 'POST', { fk_cart: cart.data.pk_cart }, currentUser)
                    .then(checkout => {
                        if (checkout.data.data_entered)
                            updateList([...checkout.data.data_entered])
                            // setUserCheckoutStep(checkout.data.step)
                        
                        if (checkout.data.payment_data)
                            updatePaymentData(checkout.data.payment_data)
                        
                        setLoading(false)
                        setShowCheckout(true)
                    })
                    .catch((err) => {
                        setError(err.message)
                    })
                setCartContext(cart.data)
            })
            .catch((err) => {
                setLoading(false)
                setShowCheckout(false)
                setError(err.message)
            })
    }, [])

    const steps = [
        <ReserveDataStep key="reserve-data-step" />,
        <PaymentMethodStep key="payment-method-step" />,
        <PaymentDataStep key="payment-data-step" />,
        <ReviewStep key="review-step" />
    ]

    const handleReloadCart = () => {
        window.location.reload();
    }

    const message = isExpiredCheckout ? 
        {title: 'O tempo de checkout foi excedido.', description: 'Clique para retomar o carrinho de reserva.'}
        :
        {title: 'Este carrinho não pertence ao usuário autenticado'}

    return (
        <Modal>
            <PageLoader loading={loading} />
            <>
            {!loading &&
                <>
                    <ModalHeader>
                        <Header showCheckout={showCheckout} />
                    </ModalHeader>
                    <ModalBody>
                        <>
                        {!isExpiredCheckout && showCheckout && error === '' ?
                            steps[currentStep - 1]
                        :
                            <Alert
                                color="red" 
                                message={message} 
                                hasButton={isExpiredCheckout}
                                handleButton={handleReloadCart}
                            />
                        }
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <Footer showCheckout={showCheckout} />
                    </ModalFooter>
                </>
            }
            </>
        </Modal>
    )
}
