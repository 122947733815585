import React, { createContext, useContext, useState, useMemo } from "react"
import { BOLETO, CREDIT_CARD, DIRECT, MERCADO_PAGO, PAGSEGURO, PIX } from "../helpers/constants";
import { mockEnabledMethods } from "../helpers/mocks/paymentMethod";
import { isValidPayerCPF, isValidPayerName, isValidCardNumber, isValidPhone, isValidCardCvv, isValidCardValidDate, isValidCardInstallments, isValidBirthday, isValidCEP, isValidStreet, isValidNumber, isValidDistrict, isValidCity, isValidUF } from "../validation";

interface PaymentMethodContextValue {
    method: string;
    paymentData: PaymentData;
    enabledMethods: Array<string>;
    dataCompleted: boolean;
    changePaymentData: (field: string, value: unknown) => void;
    selectMethod: (method: string) => void;
    updatePaymentData: (fields: PaymentData) => void;
}

type PaymentData = {
    name?: string;
    cpf?: string;
    cardNumber?: string;
    cardHolder?: string;
    cardCvv?: string;
    cardValidDate?: string;
    cardInstallments?: number;
    birthday?: string;
    cep?: string;
    street?: string;
    number?: string;
    complement?: string;
    district?: string;
    city?: string;
    uf?: string;
    phone?: string;
}


const PaymentMethodProvider = createContext<PaymentMethodContextValue>({
    method: "",
    paymentData: {},
    enabledMethods: [],
    dataCompleted: false,
    changePaymentData: () => undefined,
    selectMethod: () => undefined,
    updatePaymentData: () => undefined
})

export const usePaymentMethodContext = () : PaymentMethodContextValue => useContext(PaymentMethodProvider); 

interface PaymentMethodProps {
    children: JSX.Element | Array<JSX.Element>
}

export const PaymentMethodContext = ({ children }: PaymentMethodProps ) : JSX.Element => {
    const [ method, setMethod ] = useState(mockEnabledMethods[0])
    const [ paymentData, setPaymentData ] = useState({} as PaymentData)

    const changePaymentData = (field: string, value: unknown) => {
        setPaymentData({ ...paymentData, [field]: value })
    }

    const dataCompleted = useMemo(() => {
        switch (method) {
            case PIX:
                return isValidPayerName(paymentData.name) && isValidPayerCPF(paymentData.cpf)
            case CREDIT_CARD:
                return isValidPayerName(paymentData.cardHolder) &&
                    isValidPayerCPF(paymentData.cpf) &&
                    isValidCardNumber(paymentData.cardNumber) &&
                    isValidCardCvv(paymentData.cardCvv) &&
                    isValidCardValidDate(paymentData.cardValidDate) &&
                    isValidCardInstallments(paymentData.cardInstallments) &&
                    isValidBirthday(paymentData.birthday) &&
                    isValidCEP(paymentData.cep) &&
                    isValidStreet(paymentData.street) &&
                    isValidNumber(paymentData.number) &&
                    isValidDistrict(paymentData.district) &&
                    isValidCity(paymentData.city) &&
                    isValidUF(paymentData.uf) &&
                    isValidPhone(paymentData.phone as string)
            case BOLETO:
                return isValidPayerName(paymentData.name) &&
                    isValidPayerCPF(paymentData.cpf) &&
                    isValidPhone(paymentData.phone as string) &&
                    isValidBirthday(paymentData.birthday) &&
                    isValidCEP(paymentData.cep) &&
                    isValidStreet(paymentData.street) &&
                    isValidNumber(paymentData.number) &&
                    isValidDistrict(paymentData.district) &&
                    isValidCity(paymentData.city) &&
                    isValidUF(paymentData.uf)
            case DIRECT:
                // No validation for now
                return true
            case PAGSEGURO:
                // No validation for now
                return true
            case MERCADO_PAGO:
                // No validation for now
                return true
            default:
                return false;
        }
    }, [paymentData, method])

    return (
        <PaymentMethodProvider.Provider value={{ method, paymentData, dataCompleted, enabledMethods: mockEnabledMethods, selectMethod: setMethod, changePaymentData, updatePaymentData: setPaymentData }}>
            {children}
        </PaymentMethodProvider.Provider>
    )
}
