import React from 'react'
import { useReserveDraftContext } from '../../../contexts';
import { DateField, NumericField, PhoneField, Selector, TextField } from '../Fields';

interface ReserveCustomFields {
  type: string;
  icon: string;
  name: string;
  title: string;
  options: string;
  is_visible: boolean;
  pk_reserve_custom_fields: number;
}

interface CustomFieldsProps {
  fields: ReserveCustomFields[];
  onBlur: (title: string, value: string | number | boolean) => void;
  onChange: (title: string, value: string | number | boolean) => void;
}

export const CustomFields = (props: CustomFieldsProps) : JSX.Element => {
  const { draftReserve } = useReserveDraftContext()
  
  return (
    <>
      {
        props.fields.map((field: ReserveCustomFields) => {
          switch (field.type) {
            case 'text':
              return (
                <TextField 
                  key={`customField-${field.pk_reserve_custom_fields}`}
                  required
                  value={String(draftReserve[field.name])}
                  name={field.name}
                  label={field.title}
                  onChange={(e) => props.onChange(field.name, e.target.value)} 
                  onBlur={(e) => props.onBlur(field.name, e.target.value)} 
                />
              )
            case 'tel':
              return (
                <PhoneField
                  key={`customField-${field.pk_reserve_custom_fields}`}
                  required 
                  value={String(draftReserve[field.name])}
                  name={field.name}
                  label={field.title}
                  placeholder="(XX) XXXXX-XXXX" 
                  onChange={(e) => props.onChange(field.name, e.target.value)} 
                  onBlur={(e) => props.onBlur(field.name, e.target.value)} 
                />
              )
            case 'select':
              return (
                <Selector
                  key={`customField-${field.pk_reserve_custom_fields}`}
                  name={field.name}
                  label={field.title} 
                  value={String(draftReserve[field.name])}
                  onBlur={(e) => props.onBlur(field.name, e.target.value)} 
                  onChange={(e) => props.onChange(field.name, e.target.value)}
                  options={field.options.split(',').map((label: string, value: number) => {
                    return {label: label, value: value}
                  })}
                />
              )
            case 'number':
              return (
                <NumericField 
                  key={`customField-${field.pk_reserve_custom_fields}`}
                  value={String(draftReserve[field.name])}
                  name={field.name}
                  label={field.title}
                  required
                  onBlur={(e) => props.onBlur(field.name, e.target.value)} 
                  onChange={(e) => props.onChange(field.name, e.target.value)}
                />
              )
            case 'date':
              return (
                <DateField
                  key={`customField-${field.pk_reserve_custom_fields}`}
                  value={String(draftReserve[field.name])}
                  label={field.title}
                  required 
                  format="DD/MM/YYYY" 
                  onBlur={(e) => props.onBlur(field.name, e.target.value)} 
                  onChange={(e) => props.onChange(field.name, e.target.value)}
                />
              )
            }
          }
        )
      }
    </>
  );
}