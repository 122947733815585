import React from 'react'
import styled from 'styled-components'
import { Text } from '../../design'

interface Props {
    original: number,
    success?: boolean,
    withDiscount?: number,
}

const TextDiscount = styled(Text)`
    text-decoration: line-through;
    color: ${props => props.theme.disabled};
    position: absolute;
    bottom: 100%;
    right: 0;
    font-size: 12px;
`

export const Price = ({ original, withDiscount, success }: Props) : JSX.Element => {
    return (
        <div className="relative">
            {withDiscount ? <TextDiscount bold>R$ {parseFloat(String(original)).toFixed(2).replace(".", ",")}</TextDiscount> : ''}
            {/* <Text bold success={success}>R$ {(withDiscount || original).toFixed(2).replace(".", ",")}</Text> */}
            <Text bold success={success}>R$ {(withDiscount || parseFloat(String(original)).toFixed(2).replace(".", ","))}</Text>
        </div>
    )
}
