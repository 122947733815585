import React from 'react'
import { Modal, ModalBody } from '../../components/Modal'
import { EmailAuthentication } from './EmailAuthentication'

export const Login = () : JSX.Element => {
    return (
        <Modal>
            <ModalBody>
                <EmailAuthentication />
            </ModalBody>
        </Modal>
    )
}
