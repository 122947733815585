import React, { MouseEventHandler, useEffect, useState } from 'react'
import { FiDownload } from 'react-icons/fi'
import { useAuthContext, useCartContext, usePaymentMethodContext, usePaymentReviewContext, useReserveListContext } from '../../../contexts'
import { Expander } from '../../components/Expander'
import { TextField } from '../../components/Fields'
import { Price } from '../../components/Price'
import { Button, Text } from '../../design'
import { getSuccessMessage } from '../../../services/cartService'
import { Api } from '../../../helpers/api'
import { PageLoader } from '../../components/PageLoader'
import { BOLETO } from '../../../helpers/constants'

export const ReviewStep = (): JSX.Element => {
    const [reservesOpen, setReservesOpen] = useState(false)
    const [textCopied, setTextCopied] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')

    const { currentCart } = useCartContext()
    const { currentUser } = useAuthContext()
    const { allReserves } = useReserveListContext()
    const { method, paymentData } = usePaymentMethodContext()
    const { payment }     = usePaymentReviewContext()

    const [ loading, setLoading ] = useState(true)
    const [ error, setError ]     = useState(false)

    useEffect(() => {
        Api.call('/v3/cart/success', 'GET', { fk_adventure: currentCart.adventure.pk_adventure }, currentUser)
        .then(message => {
            setSuccessMessage(message.data.data.reserve_success_message)
        })
        .catch((err) => {
            console.log('error')
        })        
    }, [])

    const handleCopyBarCode : React.FocusEventHandler<HTMLInputElement> = (e)  => {
        if (!payment?.boleto_code)
            return

        e.target?.select()
        navigator.clipboard.writeText(payment?.boleto_code).then(() => setTextCopied(true));
    }

    const handleDownloadBoletoFile : MouseEventHandler<HTMLButtonElement> = () => {
        if (!payment?.boleto_href)
            return

        window.open(payment.boleto_href, "_blank")
    }

    return (
        <div className="pt-0 pb-2 px-2">
            {!payment ?
                <PageLoader loading />
                :
                <>
                    {!!payment.boleto_href && method === BOLETO &&
                        <div className="my-4">
                            <div className="my-2">
                                <Text small>Seu boleto foi gerado com validade de <strong>1 dia útil</strong></Text>
                                <Text small>O boleto tambem foi enviado para o seu email</Text>
                            </div>
                            <div className="my-2">
                                <TextField label="Código de barras" value={payment.boleto_code} inputProps={{ onFocus: handleCopyBarCode }} />
                                {textCopied && <Text small success>Código copiado!</Text>}
                            </div>
                            <div className="text-right flex flex-row justify-end my-2">
                                <Button outline primary onClick={handleDownloadBoletoFile}>Baixar boleto <FiDownload /></Button>
                            </div>
                        </div>
                    }
                    <div className="mb-4">
                        {!!payment.adventure.reserve_success_message && <div dangerouslySetInnerHTML={{ __html: payment.adventure.reserve_success_message }} />}
                    </div>
                    <Expander open={reservesOpen} onToggleOpen={() => setReservesOpen(!reservesOpen)} title={`Ingessos (${allReserves.length})`}>
                        {allReserves.map((reserve, index) => (
                            <div key={`reserve-${index}`}>
                                <div className="flex flex-row my-2">
                                    <div className="flex-1">
                                        <Text bold>{reserve.name}</Text>
                                        <Text small secondary>{reserve.package.name}</Text>
                                        <Price success original={reserve.package.price} withDiscount={reserve.package.priceWithDiscount} />
                                    </div>
                                    <div className="flex-1 text-right">
                                        <Text bold primary>{reserve.pk_reserve}</Text>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        ))}
                    </Expander>
                </>
            }
        </div>
    )
}
