import React from 'react'
import styled from 'styled-components'
import { Divider } from './Divider'

interface Props {
    children: JSX.Element | Array<JSX.Element>
}

const Container = styled.div`
    position: relative;
    padding: 4%;
`

export const ModalFooter = ({ children }: Props) : JSX.Element => {
    return (
        <Container>
            <Divider top />
            {children}
        </Container>
    )
}
