import styled, { DefaultTheme } from 'styled-components'


interface TitleProps {
    primary?: boolean,
    secondary?: boolean,
    success?: boolean,
    danger?: boolean,
    theme: DefaultTheme
}

const reduceColorByProps = ({primary = true, secondary, success, danger, theme, } : TitleProps) : string => {
    if(primary){
        return theme.main
    } else if (secondary) {
        return theme.secondary
    } else if (success) {
        return theme.success
    } else if (danger) {
        return theme.danger
    }
    return theme.main
}

export const Title = styled.h3<TitleProps>`
        padding: 0;
        margin: 0;
        font-size: 17px;
        font-weight: bold;
        color: ${reduceColorByProps};
    `