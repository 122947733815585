import React from 'react'
import { Button, Text } from '../../design'
import { useCartContext, useStepContext } from '../../../contexts'
import { FiChevronRight } from 'react-icons/fi';
import { FooterLoader } from './FooterLoader';

interface Props {
    showCheckout: boolean;
}

export const Footer = ({ showCheckout }: Props) : JSX.Element => {
    const { isExpiredCheckout } = useCartContext()
    const { currentStep, isReadyToContinue, nextStepLabel, advanceStep, backStep } = useStepContext()

    return (
        <>
            {showCheckout ? 
                <>
                    <div className="flex justify-between items-center">
                        <Text clickable={!isExpiredCheckout} disabled={isExpiredCheckout} secondary={!isExpiredCheckout} onClick={backStep}>{currentStep === 1 ? 'Cancelar reserva' : 'Voltar'}</Text>
                        <Button disabled={!isReadyToContinue || isExpiredCheckout} success onClick={advanceStep}>{nextStepLabel} <FiChevronRight size={16} /></Button>
                    </div>
                </>
            :
                <FooterLoader />
            }
        </>
    )
}
