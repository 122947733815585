import React, { ChangeEventHandler, FocusEventHandler, useMemo, useState } from 'react'
import { isNotEmpty, isValidEmail } from '../../../validation'
import { Label, Text } from '../../design'
import { Input } from '../../design/Input'


interface Props {
    label?: string;
    placeholder?: string;
    error?: string;
    required?: boolean;
    inputProps?:  React.InputHTMLAttributes<HTMLInputElement>;
    value?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement|HTMLTextAreaElement>;
}
  

export const EmailField = ({label, placeholder, required, error, inputProps, value, onChange, onBlur}: Props) : JSX.Element => {
    const [didInput, setDidInput] = useState(false)
    const errorMessage = useMemo(() => {
        if(error) return error

        if(!didInput) return
        
        if(required && !(value && isNotEmpty(value)))
            return "Campo obrigatório"

        if(value && !isValidEmail(value))
            return "Email inválido"

    }, [required, value, error])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDidInput(true)

        if(onChange)
            onChange(e)
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDidInput(true)

        if(onBlur)
            onBlur(e)
    }

    return (
        <>
            {label && <Label danger={!!errorMessage}>{label}</Label>}
            <Input {...inputProps} value={value} type="email" error={!!errorMessage} onChange={handleChange} onBlur={handleBlur} placeholder={placeholder} />
            {!!errorMessage && <Text danger>{errorMessage}</Text>}
        </>
    )
}
