import React, { ChangeEventHandler, FocusEventHandler, useMemo, useState } from 'react'
import { isNotEmpty, isValidDate } from '../../../validation'
import { Label, Text } from '../../design'
import { Input } from '../../design/Input'
import NumberFormat from 'react-number-format';

interface Props {
    label?: string;
    placeholder?: string;
    required?: boolean;
    error?: string;
    format: string;
    inputProps?:  React.InputHTMLAttributes<HTMLInputElement>;
    value?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement|HTMLTextAreaElement>;
}
  

export const DateField = ({label, placeholder, error, required, inputProps, value, format, onChange, onBlur}: Props) : JSX.Element => {
    const [didInput, setDidInput] = useState(false)
    const errorMessage = useMemo(() => {
        if(error) return error

        if(!didInput) return ""

        console.log(`REQUIRED ${value}` )
        if(required && !(value && isNotEmpty(value)))
            return "Campo obrigatório"

        if(value && !isValidDate(value, format))
            return "Data inválida"

        return ""

    }, [required, value, error, format, didInput])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDidInput(true)

        if(onChange)
            onChange(e)

        isNotEmpty(e.target.value)  && isValidDate(e.target.value, format) ? setDidInput(false) : setDidInput(true);
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDidInput(true)

        if(onBlur)
            onBlur(e)

        isNotEmpty(e.target.value)  && isValidDate(e.target.value, format) ? setDidInput(false) : setDidInput(true);
    }

    const fieldFormat = useMemo(() => format.replace(/D|M|Y/g, "#"), [format])

    return (
        <>
            {label && <Label data-testid="date-label" danger={!!errorMessage}>{label}</Label>}
            <NumberFormat 
                customInput={Input} 
                { ...{...inputProps, defaultValue: undefined, 'data-testid': 'date-input' }} 
                type="tel" 
                error={!!errorMessage} 
                placeholder={placeholder} 
                format={fieldFormat} 
                value={value} 
                onChange={handleChange} 
                onBlur={handleBlur}
            />
            {!!errorMessage && <Text data-testid="date-error" danger>{errorMessage}</Text>}
        </>
    )
}
