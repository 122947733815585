import React, { useMemo } from 'react'
import { Subtitle } from '../../design'
import { useCartContext, useReserveDraftContext } from '../../../contexts'
import { usePaymentMethodContext } from '../../../contexts/PaymentMethodContext'
import { BOLETO, CREDIT_CARD, DIRECT, MERCADO_PAGO, PAGSEGURO, PIX } from '../../../helpers/constants'
import { Boleto, Pix, CreditCard, Direct, PagSeguro, MercadoPago } from '../../components/PaymentMethods'

const mockLocalMessage = `Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis.\r\n

Banco: XYZ\r\n
Agência: 1234\r\n
Conta: 12345-67\r\n
Favorecido: Adalmir Adalberto\r\n
CNPJ: 12.345.678/0001-23\r\n`

const mockLocalTitle = `Transferencia Bancaria`

export const PaymentMethodStep = (): JSX.Element => {
    const { paymentData, method, selectMethod, changePaymentData, enabledMethods } = usePaymentMethodContext()
    const { updateReserve, updateLastUserCheckoutStep } = useReserveDraftContext()
    const { currentCart } = useCartContext()

    function handleChangePaymentData(field: string, value: string | number | boolean) {
        updateReserve(field, value)
        changePaymentData(field, value)
    }
    
    function handleCustomFieldsOnBlur(field: string, value: string | number | boolean) {
        updateLastUserCheckoutStep(field, value)
    }

    const methods = useMemo<Array<JSX.Element|string>>(() => {
        return currentCart.adventure.payment_methods.map((enabledMethod, index) => {
            switch (enabledMethod) {
                case PIX:
                    return (
                        <>
                            <div key={`method-${index}`} className="my-2">
                                <Pix 
                                    name={paymentData.name as string}
                                    cpf={paymentData.cpf as string}
                                    phone={paymentData.phone as string}
                                    onChange={handleChangePaymentData}
                                    onBlur={handleCustomFieldsOnBlur}
                                    selected={method === PIX}
                                    onToggleSelect={() => selectMethod(method === PIX ? "" : PIX)}
                                />
                            </div>
                            <hr />
                        </>
                    )
                case CREDIT_CARD:
                    return (
                        <>
                            <div className="my-2">
                                <CreditCard 
                                    values={paymentData}
                                    onChange={(field, value) => changePaymentData(field, value)}
                                    onBlur={handleCustomFieldsOnBlur}
                                    selected={method === CREDIT_CARD}
                                    onToggleSelect={() => selectMethod(method === CREDIT_CARD ? "" : CREDIT_CARD)}
                                />
                            </div>
                            <hr />
                        </>
                    )
                case BOLETO:
                    return (
                        <>
                            <div className="my-2">
                                <Boleto 
                                    values={paymentData}
                                    onChange={handleChangePaymentData}
                                    onBlur={handleCustomFieldsOnBlur}
                                    selected={method === BOLETO}
                                    onToggleSelect={() => selectMethod(method === BOLETO ? "" : BOLETO)}
                                />
                            </div>
                            <hr />
                        </>
                    )
                case DIRECT:
                    return (
                        <>
                            <div className="my-2">
                                <Direct 
                                    localTitle={mockLocalTitle}
                                    localMessage={mockLocalMessage}
                                    selected={method === DIRECT}
                                    onToggleSelect={() => selectMethod(method === DIRECT ? "" : DIRECT)}
                                />
                            </div>
                            <hr />
                        </>
                    )
                case PAGSEGURO:
                    return (
                        <>
                            <div className="my-2">
                                <PagSeguro 
                                    selected={method === PAGSEGURO}
                                    onToggleSelect={() => selectMethod(method === PAGSEGURO ? "" : PAGSEGURO)}
                                />
                            </div>
                            <hr />
                        </>
                    )
                case MERCADO_PAGO:
                    return (
                        <>
                            <div className="my-2">
                                <MercadoPago 
                                    selected={method === MERCADO_PAGO}
                                    onToggleSelect={() => selectMethod(method === MERCADO_PAGO ? "" : MERCADO_PAGO)}
                                />
                            </div>
                            <hr />
                        </>
                    )
                default:
                    return ""
            }
        })
    }, [enabledMethods, method, paymentData])

    return (
        <div className="pt-0 pb-2 px-2">
            <div className="mb-4 text-center">
                <Subtitle primary>Escolha o meio de pagamento</Subtitle>
            </div>
            <hr />
            {methods}
        </div>
    )
}
