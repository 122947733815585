import React, { ChangeEventHandler, FocusEventHandler, useMemo, useState } from 'react'
import { isNotEmpty, isValidPayerCPF } from '../../../validation'
import { Label, Text } from '../../design'
import { Input } from '../../design/Input'
import NumberFormat from 'react-number-format';

interface Props {
    label?: string;
    placeholder?: string;
    required?: boolean;
    error?: string;
    inputProps?:  React.InputHTMLAttributes<HTMLInputElement>;
    value?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement|HTMLTextAreaElement>;
}
  

export const CpfField = ({label, placeholder, error, required, inputProps, value, onChange, onBlur}: Props) : JSX.Element => {
    const [didInput, setDidInput] = useState(false)
    
    const errorMessage = useMemo(() => {
        if(error) return error

        if(!didInput) return

        if(required && !(value && isNotEmpty(value)))
            return "Campo obrigatório"

        if(value && !isValidPayerCPF(value))
            return "CPF inválido"

    }, [required, value, error])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDidInput(true)

        if(onChange)
            onChange(e)
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {        
        setDidInput(true)

        if (onBlur)
            onBlur(e)

    }

    return (
        <>
            {label && <Label data-testid="cpf-label" danger={!!errorMessage}>{label}</Label>}
            <NumberFormat 
                customInput={Input} 
                { ...{...inputProps, 'data-testid': "cpf-input", defaultValue: undefined}} 
                type="tel" 
                error={!!errorMessage} 
                placeholder={placeholder} 
                format="###.###.###-##" 
                value={value} 
                onChange={handleChange} 
                onBlur={handleBlur} 
            />
            {!!errorMessage && <Text data-testid="cpf-error" danger>{errorMessage}</Text>}
        </>
    )
}
