import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { Price } from '../Price';
import styled from 'styled-components'
import { Text, Title } from '../../design'
import { ProgressBar } from '../ProgressBar'
import { useNavigate } from 'react-router-dom'
import { HeaderLoader } from './HeaderLoader'
import { Cart } from '../../../domain/types/cart'
import { FiClock, FiCalendar } from 'react-icons/fi'
import { useCartContext } from '../../../contexts/CartContext'
import { usePaymentReviewContext, useStepContext, useAuthContext } from '../../../contexts'
import { PaymentStatus, PAYMENT_METHOD_ICON, PAYMENT_METHOD_LABEL, PAYMENT_STATUS_LABEL } from '../../../helpers/constants'

const Thumbnail = styled.img`
    height: 50px;
    width: 50px;
    margin-right: 5%;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
`
const LightTitle = styled(Title)`
    font-weight: normal;
`

const TextDate = styled(Text)`
    margin-left: 5px;
    font-size: 16px;
    line-height: 16px;
    vertical-align: middle;
`
interface Props {
    showCheckout: boolean;
}

export const Header = ({ showCheckout }: Props) : JSX.Element => {
    const push = useNavigate()    
    const accessedAt = moment().utc()
    const [timeLeft, setTimeLeft] = useState(moment.duration(moment(accessedAt).add(10, 'minutes').diff(moment())))
    
    const { resetUserAuthKey } = useAuthContext()
    const { currentStep }      = useStepContext()
    const { payment }          = usePaymentReviewContext()
    const { currentCart, setExpiredCheckout, isExpiredCheckout } = useCartContext()

    useEffect(() => {
        setExpiredCheckout(false)
        const interval = setInterval(() => setTimeLeft(moment.duration(moment(accessedAt).add(10, 'minutes').diff(moment()))), 1000)
        return () => clearInterval(interval)
    }, [])

    if ((timeLeft.minutes() <= 0 && timeLeft.seconds() < 0) && !isExpiredCheckout && currentStep !== 4) {
        setExpiredCheckout(true)
    }
    
    const handleLogout = async () => {
        try {            
            resetUserAuthKey()
            push("/login", { replace: true })
        } catch (error) {
            console.error(error)
        }
    }

    const startDateTime = moment(currentCart.event?.date)
    const endDateTime   = startDateTime.clone().add(currentCart.adventure?.duration)
    return (
        <>
            {showCheckout ? 
                <>
                    {currentStep !== 4 &&
                        <div className="my-4 flex flex-row justify-between">
                            <Text bold>Tempo para concluir: {` ${isExpiredCheckout ? '00:00' : `${timeLeft.minutes()}:${timeLeft.seconds()}`}`}</Text>
                            <Text clickable secondary onClick={() => handleLogout()}>Sair</Text>
                        </div>
                    }
                    <div className="my-4 flex flex-row">
                        <Thumbnail src={currentCart.adventure?.thumbnail} alt={currentCart.adventure?.title} />
                        <LightTitle>{currentCart.adventure?.title}</LightTitle>
                    </div>
                    <div className="my-4 flex flex-row justify-between">
                        <div className="my-0 flex flex-row">
                            <FiCalendar style={{ marginTop: -2 }} color="#999" size={18} />
                            <TextDate>{startDateTime.format('DD/MM/YYYY')}</TextDate>
                        </div>
                        <div className="my-0 flex flex-row">
                            <FiClock style={{ marginTop: -2 }} color="#999" size={18} /> 
                            <TextDate>{startDateTime.format('HH:mm')} - {endDateTime.format('HH:mm')}</TextDate>
                        </div>
                    </div>
                    <div className="my-4">
                        <ProgressBar steps={4} progress={currentStep} />
                    </div>
                    <div className="my-4 flex flex-row justify-between">
                        <Text success>{currentCart.event?.totalQuantity} Ingressos</Text>
                        <Price original={currentCart.event.totalPrice} withDiscount={currentCart.event.totalPriceWithDiscount} />
                    </div>
                    {!!payment && currentStep === 4 &&
                        <div className="my-4 flex flex-row justify-between">
                            <Text className="flex-1 flex flex-row" bold>{PAYMENT_METHOD_ICON[payment.method]} <span className="ml-3" data-testid="header-payment-method">{PAYMENT_METHOD_LABEL[payment.method]}</span></Text>
                            <Text 
                                success={payment.payment_status === PaymentStatus.CONFIRMED} 
                                danger={payment.payment_status === PaymentStatus.CANCELED} 
                                warning={payment.payment_status === PaymentStatus.WAITING}
                                small 
                                data-testid="header-payment-status"
                            >{PAYMENT_STATUS_LABEL[payment.payment_status]}</Text>
                        </div>
                    }
                </>
            :
                <HeaderLoader />
            }
        </>
    )
}