import React, { ChangeEventHandler, FocusEventHandler, useMemo, useState } from 'react'
import { isNotEmpty, isValidCEP } from '../../../validation'
import { Label, Text } from '../../design'
import { Input } from '../../design/Input'
import NumberFormat from 'react-number-format';

interface Props {
    label?: string;
    placeholder?: string;
    required?: boolean;
    error?: string;
    inputProps?:  React.InputHTMLAttributes<HTMLInputElement>;
    value?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement|HTMLTextAreaElement>;
}
  

export const CepField = ({label, placeholder, error, required, inputProps, value, onChange, onBlur}: Props) : JSX.Element => {
    const [didInput, setDidInput] = useState(false)
    
    const errorMessage = useMemo(() => {
        if(error) return error

        if(!didInput) return ""

        if(required && !(value && isNotEmpty(value)))
            return "Campo obrigatório"

        if(!!value && !isValidCEP(value.trim()))
            return "CEP inválido"
        
        return ""

    }, [required, value, error])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDidInput(true)

        if(onChange)
            onChange(e)
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {        
        setDidInput(true)

        if (onBlur)
            onBlur(e)

            
        isNotEmpty(e.target.value) ? setDidInput(false) : setDidInput(true);
    }

    return (
        <>
            {label && <Label data-testid="cep-label" danger={!!errorMessage}>{label}</Label>}
            <NumberFormat 
                customInput={Input} 
                { ...{...inputProps, 'data-testid': "cep-input", defaultValue: undefined}} 
                type="text" 
                error={!!errorMessage} 
                placeholder={placeholder} 
                format="########" 
                value={value} 
                onChange={handleChange} 
                onBlur={handleBlur}
            />
            {!!errorMessage && <Text data-testid="cep-error" danger>{errorMessage}</Text>}
        </>
    )
}
