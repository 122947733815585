import React, { createContext, useContext, useMemo, useState } from "react"
import { LoginSteps } from "../helpers/constants";
import { isValidEmail } from "../validation";

export type AuthData = {
    email?: string,
    password?: string,
    recaptcha?: string | null,
    confirmPassword?: string,
    name?: string,
    phone?: string,
    privacyChecked?: boolean,
    auth_key?: number | null
}

interface AuthContextValue {
    step: LoginSteps,
    changeStep: (value: LoginSteps) => void,
    isStepComplete: boolean,
    authData: AuthData,
    currentUser?: number|null,
    changeAuthData: (value: string | null | boolean, prop: keyof AuthData) => void,
    setUserAuthKey: (value?: number|null) => void,
    resetUserAuthKey: () => void
} 

type MockableValues = {
    
}

const AuthContextProvider = createContext<AuthContextValue>({
    step: LoginSteps.SELECT_EMAIL,
    authData: {},
    isStepComplete: false,
    changeAuthData: () => undefined,
    changeStep: () => undefined,
    setUserAuthKey: () => undefined,
    resetUserAuthKey: () => undefined
})

export const useAuthContext = () : AuthContextValue => useContext(AuthContextProvider); 

interface AuthContextProps {
    children: JSX.Element | Array<JSX.Element>,
    mock?: MockableValues
}

export const AuthContext = ({ children }: AuthContextProps ) : JSX.Element => {
    const [authData, setAuthData] = useState<AuthData>({});
    const [step, setStep] = useState<LoginSteps>(LoginSteps.SELECT_EMAIL);
    const [currentUser, setCurrentUser] = useState<number|null>(Number(sessionStorage.getItem('auth_key')) || null)
    const changeAuthData = (value: string | null | boolean, prop: keyof AuthData) => {
        setAuthData({
            ...authData,
            [prop]: value
        })
    }

    const setUserAuthKey = (value?: number | null) => {
        if (value) {
            sessionStorage.setItem('auth_key', `${value}`)
            setCurrentUser(value)
        } else {
            const userAuthKey = sessionStorage.getItem('auth_key')

            if (userAuthKey) {
                setCurrentUser(JSON.parse(userAuthKey))
            } else {
                setCurrentUser(null)
            }
        }
    }

    const resetUserAuthKey = () => {
        setCurrentUser(null)
        sessionStorage.setItem('auth_key', '');
    }

    const isStepComplete = useMemo<boolean>(() => {
        switch (step) {
            case LoginSteps.SELECT_EMAIL:
                return !!authData.email 
                    && !!authData.recaptcha
                    && isValidEmail(authData.email)
            case LoginSteps.ENTER_PASSWORD:
                return !!authData.password?.length
            case LoginSteps.REGISTER_NEW_ACCOUNT:
                return !!authData.password?.length
                    && authData.password === authData.confirmPassword
                    && !!authData.name?.length
                    && !!authData.phone?.length
                    && !!authData.privacyChecked
            default:
                return false
        }
    }, [authData, step]);

    return (
        <AuthContextProvider.Provider value={{ 
            isStepComplete, 
            authData, 
            step, 
            currentUser,
            changeStep: setStep, 
            changeAuthData,
            setUserAuthKey,
            resetUserAuthKey
            }}>
            {children}
        </AuthContextProvider.Provider>
    )
}
