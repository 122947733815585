import React, { createContext, useContext, useState } from "react"
import moment from 'moment'
import { Cart } from "../domain/types/cart"
import { updateAccessedAndExpiredTime } from '../services/cartService'
import { useAuthContext } from "./AuthContext"

interface CartContextValue {
    currentCart: Cart,
    isExpiredCheckout: boolean
    setCartContext: (value: Cart) => void,
    setExpiredCheckout: (value: boolean) => void
}

const emptyCart: Cart = {
    pk_cart: 0,
    pk_request: 0,
    adventure: {
        pk_adventure: 0,
        title: '',
        thumbnail: '',
        company: '',
        duration: '',
        reserve_custom_fields: [],
        payment_methods: []
    },
    event: {
        pk_request: 0,
        date: '',
        hour: [],
        packages: [],
        totalPrice: 0.00,
        totalPriceWithDiscount: 0.00,
        totalQuantity: 0
    }
}

const CartContextProvider = createContext<CartContextValue>({
    currentCart: emptyCart,
    isExpiredCheckout: false,
    setCartContext: () => undefined,
    setExpiredCheckout: () => undefined
})

export const useCartContext = () : CartContextValue => useContext(CartContextProvider); 

interface CartContextProps {
    children: JSX.Element | Array<JSX.Element>
}

export const CartContext = ({ children }: CartContextProps ) : JSX.Element => {
    const { currentUser } = useAuthContext()
    const [currentCart, setCurrentCart] = useState<Cart>(emptyCart)
    const [isExpiredCheckout, setIsExpiredCheckout] = useState<boolean>(false)

    const setCartContext = (value: Cart) => {
        setCurrentCart(value)
    }

    const setExpiredCheckout = (value: boolean) => {
        if (!value) {
            updateAccessedAndExpiredTime({pk_cart: currentCart.pk_cart, accessed_at: moment().utc(true), expired_at: null}, currentUser)
        } else {
            updateAccessedAndExpiredTime({pk_cart: currentCart.pk_cart, accessed_at: null, expired_at: moment().utc(true)}, currentUser)
        }

        setIsExpiredCheckout(value)
    }

    return (
        <CartContextProvider.Provider value={{ 
            currentCart, 
            setCartContext,
            isExpiredCheckout,
            setExpiredCheckout
            }}>
            {children}
        </CartContextProvider.Provider>
    )
}
