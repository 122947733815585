import styled, { DefaultTheme } from 'styled-components'

interface LabelProps {
    primary?: boolean,
    secondary?: boolean,
    danger?: boolean,
    success?: boolean
    bold?: boolean,
    theme: DefaultTheme
}

const reduceColorByProps = ({primary, secondary, success, danger, theme, } : LabelProps) : string => {
    if(primary){
        return theme.main
    } else if (secondary) {
        return theme.secondary
    } else if (success) {
        return theme.success
    } else if (danger) {
        return theme.danger
    }
    return theme.black
}

export const Label = styled.label<LabelProps>`
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: ${reduceColorByProps};
    font-weight: ${props => props.bold ? 'bold' : 'normal'};
`