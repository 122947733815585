import React, { useEffect, useRef } from 'react'
import { FiUser, FiCheck } from 'react-icons/fi'
import { useCartContext, useReserveDraftContext } from '../../../contexts'
import { Reserve, ReserveForm } from '../../../domain/types'
import { isReserveCompleted } from '../../../validation'
import { Button, Subtitle, Text } from '../../design'
import { Checkbox } from '../../design/Checkbox'
import { Link } from '../../design/Link'
import { CustomFields } from '../CustomFields'
import { Expander } from '../Expander'
import { EmailField, PhoneField } from '../Fields'
import { TextField } from '../Fields/TextField'
import { Price } from '../Price'

interface ExpanderProps {
    active: boolean;
    reserve: Reserve;
    pos: number;
    onToggle: () => void;
}

export const ReserveExpander = ({active, reserve, pos, onToggle}: ExpanderProps) : JSX.Element => {
    const { 
        draftReserve, 
        draftCompleted, 
        loadingVoucherRequest, 
        voucherError, 
        applyVoucher, 
        removeVoucher, 
        initDraft, 
        updateReserve, 
        saveReserve, 
        updateLastUserCheckoutStep 
    } = useReserveDraftContext()
    const { currentCart } = useCartContext()

    const expanderTitleRef = useRef<HTMLDivElement>(null)
    const reserveCompleted = isReserveCompleted(reserve as ReserveForm)

    useEffect(() => {
        if(active){
            expanderTitleRef.current?.scrollIntoView({ block: "start", behavior: "smooth" })
            initDraft()
        }
    }, [active])

    function handleCustomFieldsChange(field: string, value: string | number | boolean) {
        updateReserve(field, value)
    }

    function handleCustomFieldsOnBlur(field: string, value: string | number | boolean) {
        updateLastUserCheckoutStep(field, value)
    }

    const title = (
        <>
            <Subtitle className="pr-5 flex flex-row" success={reserveCompleted}><FiUser />{reserveCompleted && <FiCheck />}</Subtitle>
            <Subtitle>{reserve.name ? reserve.name : `Ingresso ${pos}`}</Subtitle>
        </>
    )

    const subtitle = (
        <>
            <Text secondary small>{reserve.package.name}</Text>
            <Price original={reserve.package.price} withDiscount={reserve.package.priceWithDiscount} />
        </>
    )

    return (
        <div ref={expanderTitleRef} className="p-2">
            <Expander  
                title={title}
                subtitle={subtitle}
                open={active}
                onToggleOpen={onToggle}
            >
                <div className="my-4">
                    <TextField required value={draftReserve.name} onChange={(e) => updateReserve('name', e.target.value)} onBlur={(e) => updateLastUserCheckoutStep('name', e.target.value)} label="Nome completo*" placeholder="Fulano de tal" />
                </div>
                <div className="my-4">
                    <EmailField required value={draftReserve.email} onChange={(e) => updateReserve('email', e.target.value)} onBlur={(e) => updateLastUserCheckoutStep('email', e.target.value)} label="Email*" placeholder="email@email.com" />
                </div>
                <div className="my-4">
                    <PhoneField required value={draftReserve.phone} onChange={(e) => updateReserve('phone', e.target.value)} onBlur={(e) => updateLastUserCheckoutStep('phone', e.target.value)} label="Telefone*" placeholder="(XX) XXXXX-XXXX" />
                </div>
                <div className="my-4">
                    <TextField value={draftReserve.observation} onChange={(e) => updateReserve('observation', e.target.value)} onBlur={(e) => updateLastUserCheckoutStep('observation', e.target.value)} multipleLines label="Observação" placeholder="Atendimento especial etc."  />
                </div>
                <CustomFields
                    fields={currentCart.adventure?.reserve_custom_fields}
                    onChange={handleCustomFieldsChange} 
                    onBlur={handleCustomFieldsOnBlur}
                />
                <div className="my-4">
                    <Text secondary>Possui um cupom ou voucher?</Text>
                    <div className="flex flex-row">
                        <div className="flex-1">
                            <TextField label={'Voucher'} error={voucherError} placeholder="123456" inputProps={{ disabled: !!draftReserve.pk_reserve }} value={draftReserve.voucher_code} onChange={(e) => updateReserve('voucher_code', e.target.value)} onBlur={(e) => updateLastUserCheckoutStep('voucher_code', e.target.value)} />
                        </div>
                        {!draftReserve.pk_reserve && <Button disabled={!draftReserve.voucher_code} onClick={applyVoucher} className="ml-2" success>{loadingVoucherRequest ? "Carregando..." : "Aplicar"}</Button>}
                        {!!draftReserve.pk_reserve && <Button danger={!!draftReserve.pk_reserve} onClick={removeVoucher} className="ml-2">Remover</Button>}
                    </div>
                </div>
                <div className="my-4">
                    <label>
                        <Checkbox checked={draftReserve.accept_privacy_terms} onChange={(e) => updateReserve('accept_privacy_terms', e.target.checked)} onBlur={(e) => updateLastUserCheckoutStep('accept_privacy_terms', e.target.value)} />
                        Os dados aqui inseridos são verdadeiros e todos os envolvidos estão de acordo com a <Link href="#">politica de privacidade de dados</Link> de Área 51 Airsoft
                    </label>
                </div>
                <div className="my-4">
                    <label>
                        <Checkbox checked={draftReserve.accept_responsability_terms} onChange={(e) => updateReserve('accept_responsability_terms', e.target.checked)} onBlur={(e) => updateLastUserCheckoutStep('accept_responsability_terms', e.target.value)} />
                        Está ciente e concorda (o reponsável legal, se for o caso) com o <Link href="#">Contrato e Termo(s) de conhecimento de risco e responsabilidade</Link> (uma cópia será enviada por email junto com a reserva)
                    </label>
                </div>
                <div className="my-4 flex justify-end">
                    <Button disabled={!draftCompleted} onClick={saveReserve} success>Salvar</Button>
                </div>
            </Expander>
            <hr />
        </div>
    )
}
