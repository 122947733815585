import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { FiLogIn } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../../contexts'
import { LoginSteps, UserType } from '../../../../helpers/constants'
import { checkEmailForAuth } from '../../../../services/authService'
import { EmailField } from '../../../components/Fields'
import { Button, Title } from '../../../design'

export const FormSelectEmail: React.FC = () => {
    const { authData, isStepComplete, changeAuthData, changeStep, setUserAuthKey, currentUser } = useAuthContext()
    const push = useNavigate()

    const handleContinue = async () => {
        if(!authData.email)
            return

        try {
            const res = await checkEmailForAuth(authData.email)
            
            switch (res.user_type) {
                case UserType.REGISTRED:
                    changeStep(LoginSteps.ENTER_PASSWORD)    
                    break;
                case UserType.REGISTRED_WITHOUT_PASSWORD:
                    if(!res.user.auth_key)
                        return
    
                    setUserAuthKey(res.user.auth_key)
                    push("/", { replace: true })
                    break;
                case UserType.NOT_REGISTRED:
                    changeStep(LoginSteps.EMAIL_NOT_FOUND)
                    break;
                default:
                    changeStep(LoginSteps.EMAIL_NOT_FOUND)
                    break;
            }
        } catch (error: any) {
            if(error.status === 404){
                changeStep(LoginSteps.EMAIL_NOT_FOUND)
            }
        }
    }

    return (
        <>
            <div className="my-4">
                <FiLogIn size={50} />
            </div>
            <div className="my-4">
                <Title primary>Preencha os dados de cadastro</Title>
            </div>
            <div className="my-4 w-full">
                <EmailField value={authData.email} onChange={(e) => changeAuthData(e.target.value, 'email')} label="Email" placeholder="email@email.com" />
            </div>
            <div className="my-4 w-full flex justify-center">
                <ReCAPTCHA 
                    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    onChange={(token) => changeAuthData(token, 'recaptcha')}
                />
            </div>
            <div className="mt-4 w-full">
                <Button disabled={!isStepComplete} onClick={handleContinue} large success>Continuar</Button>
            </div>
        </>
    )
}