import React, { ChangeEventHandler, FocusEventHandler, InputHTMLAttributes, useMemo, useState } from 'react'
import { isNotEmpty, isValidNumeric } from '../../../validation'
import { Label, Text } from '../../design'
import { Input } from '../../design/Input'
import NumberFormat from 'react-number-format';

interface Props extends InputHTMLAttributes<HTMLInputElement>{
    label?: string;
    placeholder?: string;
    required?: boolean;
    format?: string;
    error?: string;
    inputProps?:  React.InputHTMLAttributes<HTMLInputElement>;
    value?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement|HTMLTextAreaElement>;
}
  

export const NumericField = ({label, placeholder, error, format, required, inputProps, value, onChange, onBlur, name}: Props) : JSX.Element => {
    const [didInput, setDidInput] = useState(false)
    
    const errorMessage = useMemo(() => {
        if(error) return error

        if(!didInput) return

        if(required && !(!!value && isNotEmpty(value)))
            return "Campo obrigatório"

        if(!!value && !isValidNumeric(value, format))
            return "Número inválido"
        
        return ""

    }, [required, value, format, error, didInput])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDidInput(true)

        if(onChange)
            onChange(e)

        isNotEmpty(e.target.value) ? setDidInput(false) : setDidInput(true);
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {        
        setDidInput(true)

        if (onBlur)
            onBlur(e)

        isNotEmpty(e.target.value) ? setDidInput(false) : setDidInput(true);
    }

    return (
        <>
            {label && <Label data-testid="numeric-label" danger={!!errorMessage}>{label}</Label>}
            <NumberFormat 
                customInput={Input} 
                { ...{...inputProps, 'data-testid': "numeric-input", defaultValue: undefined}} 
                name={name}
                type="text" 
                error={!!errorMessage} 
                placeholder={placeholder} 
                format={format} 
                value={value} 
                onChange={handleChange}
                onBlur={handleBlur} 
            />
            {!!errorMessage && <Text data-testid="numeric-error" danger>{errorMessage}</Text>}
        </>
    )
}
