import React, { useState } from 'react';
import { FiLogIn } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts';
import { authAccountByEmail } from '../../../../services/authService';
import { PasswordField } from '../../../components/Fields';
import { PageLoader } from '../../../components/PageLoader';
import { Text, Link, Title, Button } from '../../../design'

export const FormPassword: React.FC = () => {
    const { authData, isStepComplete, changeAuthData, setUserAuthKey } = useAuthContext()

    const [loading, setLoading] = useState(false)

    const push = useNavigate()
    const handleContinue = async () => {
        setLoading(true)
        if(!authData.email || !authData.password)
            return
            
        try {
            const user = await authAccountByEmail({ email: authData.email, password: authData.password })
            setUserAuthKey(user.auth_key)
            setLoading(false)
            push("/")
        } catch (error)  {
            setLoading(false)
            console.error(error)
        }
    }

    return (
        <>
            <PageLoader loading={loading} />

            {!loading && 
                <>
                <div className="my-4">
                    <FiLogIn size={50} />
                </div>
                <div className="my-4">
                    <Title primary>Você já possui cadastro</Title>
                </div>
                <div className="mt-4 mb-2 w-full">
                    <PasswordField value={authData.password} onChange={(e) => changeAuthData(e.target.value, 'password')} label="Senha" placeholder="*********" />
                </div>
                <div className="mt-2 mb-4 w-full">
                    <Text small>Esqueceu a senha? <Link href="#">Clique aqui</Link></Text>
                </div>
                <div className="mt-4 w-full">
                    <Button disabled={!isStepComplete} onClick={handleContinue} large success>Login</Button>
                </div>
                <div className="mt-4 w-full">
                    <Button large secondary>Continuar sem senha</Button>
                </div>
                </>
            }
        </>
    )
}