import React from 'react'
import { FiAlertCircle } from 'react-icons/fi'
import { Button, Text } from '../../design'

interface AlertMessage {
  title: string,
  description?: string
}

interface PropsAlert {
  color: string,
  message: AlertMessage,
  hasButton?: boolean,
  handleButton?: React.MouseEventHandler<HTMLButtonElement> | undefined,
}

export const Alert = ({ color, message, hasButton, handleButton }: PropsAlert): JSX.Element => {
  return (
    <>
      <div className="flex flex-col items-center">
        <FiAlertCircle color={color} />
        <h3>{message.title}</h3>
        {message.description && <Text secondary small>{message.description}</Text>}
        {hasButton && <Button success onClick={handleButton}>Continuar</Button>}
      </div>
    </>
  )
}