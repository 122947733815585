import styled, { DefaultTheme } from 'styled-components'

interface SubtitleProps {
    primary?: boolean,
    secondary?: boolean,
    success?: boolean,
    theme: DefaultTheme
}


const reduceColorByProps = ({primary, secondary, success, theme, } : SubtitleProps) : string => {
    if(primary){
        return theme.main
    } else if (secondary) {
        return theme.secondary
    } else if (success) {
        return theme.success
    }
    return theme.black
}

export const Subtitle = styled.h5<SubtitleProps>`
        margin: 0;
        font-size: 17px;
        font-weight: bold;
        color: ${reduceColorByProps};
    `
