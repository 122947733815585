import React, { createContext, useContext, useEffect, useState, useMemo } from "react"
import { useReserveListContext, useStepContext, useAuthContext, useCartContext, usePaymentMethodContext } from ".";
import { Package, Reserve, ReserveForm } from "../domain/types";
import { Api } from "../helpers/api";
import { updateCheckoutStep } from '../services/cartService'
import { isReserveCompleted } from "../validation";

const formatPhone = (tel: string): string => {
    tel = tel.replace(/\D/g,"")
    tel = tel.replace(/(.{2})(\d)/,"($1) $2")
    if(tel.length == 10) {
        tel=tel.replace(/(.{1})$/,"-$1")
    } else if (tel.length == 11) {
        tel=tel.replace(/(.{2})$/,"-$1")
    } else if (tel.length == 12) {
        tel=tel.replace(/(.{3})$/,"-$1")
    } else if (tel.length >= 13) {
        tel=tel.replace(/(.{4})$/,"-$1")
    }
    return tel;
}

interface ReserveDraftContextValue {
    draftReserve: ReserveForm;
    draftCompleted: boolean;
    loadingVoucherRequest: boolean;
    voucherError?: string;
    removeVoucher: () => void;
    applyVoucher: () => void;
    updateReserve: (field: string, value: unknown) => void;
    updateLastUserCheckoutStep: (field?: string, value?: unknown) => void;
    initDraft: () => void;
    saveReserve: () => void;
    listDraftReserve: Array<ReserveForm>;
    setListDraftReserveContext: () => void;
}

const emptyPackage: Package = {
    pkPackage: 0,
    price: 0,
    priceWithDiscount: 0,
    name: "",
    quantity: 0,
    fkAdventure: 0
}

class ReserveFormDraft implements ReserveForm {
    [key: string]: unknown;
    pk_reserve?: number | undefined;
    name = "";
    email = "";
    phone = "";
    observation = "";
    fk_adventure = 0;
    package = emptyPackage;
    voucher_code = "";
    accept_privacy_terms = false;
    accept_responsability_terms = false;

    constructor(reserve?: Reserve){
        if(!reserve) return

        for (const key in reserve) {
            this[key] = reserve[key]
        }
    }
}

const defaultDraft = new ReserveFormDraft();

const ReserveDraftContextProvider = createContext<ReserveDraftContextValue>({
    draftReserve: defaultDraft,
    draftCompleted: isReserveCompleted(defaultDraft),
    loadingVoucherRequest: false,
    removeVoucher: () => undefined,
    applyVoucher: () => undefined,
    initDraft: () => undefined,
    updateReserve: () => undefined,
    updateLastUserCheckoutStep: () => undefined,
    saveReserve: () => undefined,
    listDraftReserve: [],
    setListDraftReserveContext: () => undefined

})

export const useReserveDraftContext = () : ReserveDraftContextValue => useContext(ReserveDraftContextProvider); 

interface ContextProps {
    children: JSX.Element | Array<JSX.Element>
}

export const ReserveDraftContext = ({ children }: ContextProps ) : JSX.Element => {
    const [ draftReserve, setDraftReserve ] = useState<ReserveForm>(defaultDraft)
    const [ voucherError, setVoucherError ] = useState<string|undefined>(undefined)
    const [ loadingVoucherRequest, setLoadingVoucherRequest ] = useState(false)
    const [ listDraftReserve, setListDraftReserve ] = useState<Array<any>>([])
    
    const { currentReserveIndex, allReserves, updateList, selectReserve } = useReserveListContext()
    const { paymentData } = usePaymentMethodContext()
    const { currentCart } = useCartContext()
    const { currentUser } = useAuthContext()
    const { currentStep } = useStepContext()
    
    const draftCompleted = useMemo(() => isReserveCompleted(draftReserve), [draftReserve])
    
    const setListDraftReserveContext = () => {
        const drafts = allReserves.map(() => {
            return new ReserveFormDraft()
        });

        setListDraftReserve(drafts)
    }

    useEffect(() => {
        setListDraftReserveContext()
    }, [])

    const updateReserve = (field: string, value: any) => {
        setDraftReserve({ ...draftReserve, [field]: value })
    }

    const updateLastUserCheckoutStep = async (field?: string, value?: any) => {
        setListDraftReserve(allReserves)

        if(currentReserveIndex !== undefined) {
            listDraftReserve[currentReserveIndex] = { ...draftReserve, voucher_applied: undefined }
        }

        let dataCheckoutStep = {}
        if (field === 'step' && value) {
            dataCheckoutStep = { pk_cart: currentCart.pk_cart, currentReserveIndex, currentStep: currentStep + 1, draftReserve, listDraftReserve: allReserves, paymentData }
        } else {
            dataCheckoutStep = { pk_cart: currentCart.pk_cart, currentReserveIndex, currentStep: currentStep, draftReserve, listDraftReserve, paymentData }
        }

        await updateCheckoutStep(dataCheckoutStep, currentUser)
    }

    const initDraft = () => {
        if(currentReserveIndex === undefined) return
        setDraftReserve(new ReserveFormDraft(allReserves[currentReserveIndex]))
    }

    const saveReserve = () => {
        if(currentReserveIndex === undefined || !draftCompleted) return;

        allReserves[currentReserveIndex] = { ...draftReserve, voucher_applied: undefined }
        updateList([...allReserves])

        const nextToOpen = allReserves.findIndex((reserve) => !isReserveCompleted(reserve as ReserveForm))
        if (nextToOpen !== -1) {
            selectReserve(nextToOpen)
        } else {
            selectReserve()
        }
    }

    const applyVoucher = () => {
        if(currentReserveIndex === undefined) return;
        
        const currentReserve = allReserves[currentReserveIndex]
        
        const payload = {
            code: draftReserve.voucher_code,
            fk_adventure: currentReserve.fk_adventure,
            fk_package: currentReserve.package.pkPackage
        }
        
        setVoucherError(undefined)
        setLoadingVoucherRequest(true)
        
        Api.call('/v2/reserve/apply-voucher', 'POST', payload)
        .then((res) => {
            if( res.status === 200) {
                const { pk_reserve, name, phone, email, observation } = res.data.data
                setDraftReserve({ ...draftReserve, pk_reserve, name, phone: formatPhone(phone), email, observation, accept_privacy_terms: true, accept_responsability_terms: true, voucher_applied: true })
            } else {
                setVoucherError("Erro ao aplicar o código")
            }
            setLoadingVoucherRequest(false)
        })
        .catch(() => {
            setVoucherError("Erro ao aplicar o código")
            setLoadingVoucherRequest(false)
        })
    }

    const removeVoucher = () => {
        if(currentReserveIndex === undefined) return;
        const clearedVoucher = { ...draftReserve, pk_reserve: undefined, name: "", phone: "", email: "", observation: "", voucher_code: "", accept_privacy_terms: false, accept_responsability_terms: false }

        allReserves[currentReserveIndex] = clearedVoucher
        updateList([...allReserves])

        setDraftReserve(clearedVoucher)
    }

    useEffect(() => {
        if(draftReserve.pk_reserve && draftReserve.voucher_applied)
            saveReserve()
    }, [draftReserve])

    return (
        <ReserveDraftContextProvider.Provider value={{ 
            draftReserve, 
            loadingVoucherRequest, 
            draftCompleted, 
            voucherError, 
            applyVoucher, 
            removeVoucher, 
            updateReserve, 
            updateLastUserCheckoutStep, 
            initDraft, 
            saveReserve,
            listDraftReserve,
            setListDraftReserveContext
            }}>
            {children}
        </ReserveDraftContextProvider.Provider>
    )
}
